import axios from "axios";

const api = axios.create({
  //baseURL: "https://api.baserow.io/api/database/rows/table",
  //baseURL: "http://localhost/api/database/rows/table/",
  //baseURL: "https://selfserve.decipherinc.com/api/v1/surveys/selfserve/",
  //baseURL: "http://3.25.88.193/api/database/rows/table",
});

//api.defaults.headers["X-API-KEY"] = `${process.env.REACT_APP_API_KEY}`;

export default api;
