import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoadingSpinner = () => {
  return (
    <div className="loader-container">
      <RotatingLines type="Puff" color="#00BFFF" height={70} width={70} />
    </div>
  );
};

export default LoadingSpinner;
