import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { OPS_REPORT_QUESTIONS } from "../../constants/constants";
import PerceivedBenefitsBarChartQ48_1 from "./PerceivedBenefitsBarChartQ48_1";
import LikelihoodOfAdoptionBarChartQ49 from "./LikelihoodOfAdoptionBarChartQ49";
import LikelihoodOfRecommendationBarChartQ50 from "./LikelihoodOfRecommendationBarChartQ50";
import WillingnessToPayHistogramChartQ51 from "./WillingnessToPayHistogramChartQ51";
import { Col, Row } from "react-bootstrap";
import PerceivedBenefitsBarChartQ48 from "./PerceivedBenefitsBarChartQ48";

function OpsReport() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        OPS_REPORT_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q48"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ48
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q48"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ48_1
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {filteredQuestionListContext.state.allQuestions && (
              <>
                <Col sm={12} md={6}>
                  <LikelihoodOfAdoptionBarChartQ49
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
                <Col sm={12} md={6}>
                  <LikelihoodOfRecommendationBarChartQ50
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              </>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q49"
            ) && (
              <Col>
                <LikelihoodOfAdoptionBarChartQ49
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q50"
            ) && (
              <Col>
                <LikelihoodOfRecommendationBarChartQ50
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q51"
              )) && (
              <Col>
                <WillingnessToPayHistogramChartQ51
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default OpsReport;
