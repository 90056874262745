import { Button, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import { HexColorInput, HexColorPicker } from "react-colorful";

function ColorPickerModal(props) {
  const {
    show,
    onClose,
    onSaveChanges,
    colorList,
    onColorChange,
    onColorRemove,
    onColorAdd,
    onFocusChange,
    onBlurChange,
    onRevertChanges,
  } = props;

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Customize Branding</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {colorList.map((singleColor, index) => (
            <div key={index}>
              <FormGroup className="mb-3" as={Row}>
                <Form.Label column xs={2} md={2}>
                  Color {index + 1}:
                </Form.Label>
                <Col xs={7} md={7} className="small">
                  {
                    <HexColorPicker
                      color={singleColor.color}
                      onChange={(value) => onColorChange(index, value)}
                    />
                  }
                  <HexColorInput
                    style={{ padding: "0.6rem 0.8rem" }}
                    color={singleColor.color}
                    onChange={(value) => {
                      onColorChange(index, value);
                    }}
                    onFocus={() => onFocusChange(index)}
                    onBlur={() => onBlurChange(index)}
                  />
                </Col>
                <Col xs={3} md={3}>
                  {colorList.length > 1 && (
                    <Button
                      variant="danger"
                      onClick={() => onColorRemove(index)}
                    >
                      Remove
                    </Button>
                  )}
                </Col>
              </FormGroup>
              {colorList.length - 1 === index && (
                <Form.Label>
                  <Button variant="primary" onClick={onColorAdd}>
                    Add More Color
                  </Button>
                </Form.Label>
              )}
            </div>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onSaveChanges}>
          Save Changes
        </Button>
        <Button onClick={onRevertChanges}>Reset</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ColorPickerModal;
