import Select from "react-select";
import { useAPI } from "../../ApiContext";
import { useContext } from "react";
import { FilteredQuestionListContext } from "../../App";

function QuestionsDropDown(props) {
  var kpis = [
    "Q1",
    "Q3",
    "Q4",
    "Q5",
    "Q6_1",
    "Q6_2",
    "Q6_3",
    "Q7",
    "Q8",
    "Q9",
    "Q12",
    "Q13",
    "Q14",
    "Q16",
    "Q17",
    "Q19",
    "Q24",
    "Q25",
    "Q26",
    "Q27",
    "Q28",
    "Q30",
    "Q31",
    "Q32",
    "Q33",
    "Q36",
    "Q37",
    "Q38",
    "Q39",
    "Q42",
    "Q43",
    "Q44",
    "Q45",
    "Q48",
    "Q49",
    "Q50",
    "Q51",
    "Q54",
    "Q55",
  ];

  const { dashboardData } = useAPI();

  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  const { filteredQuestionListState, updateFilteredQuestionListState } =
    filteredQuestionListContext;

  function getQuestionList() {
    const { datamap } = dashboardData;

    var test =
      datamap &&
      datamap.questions &&
      datamap.questions.filter((item) => {
        return item.qlabel.startsWith("Q") && item.flags !== "x";
      });

    var temp =
      test &&
      test.map((obj, index) => ({
        value: obj.qlabel,
        label: obj.qlabel + ": " + obj.qtitle,
        question: obj.qlabel,
      }));

    return temp && temp.filter((el) => kpis.indexOf(el.question) >= 0);
  }

  return (
    <div>
      <Select
        name="countries"
        options={getQuestionList()}
        // defaultValue={{
        //   value: filterState.countryFilterValue,
        //   label: filterState.countryFilterLabel,
        // }}
        isClearable={true}
        // onChange={(event) =>
        //   handleChange("COUNTRY", {
        //     value: event?.value,
        //     label: event?.label,
        //   })
        // }
        onChange={(event) =>
          updateFilteredQuestionListState(event.map((obj) => obj.value))
        }
        isMulti
      />
    </div>
  );
}

export default QuestionsDropDown;
