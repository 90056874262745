import {
  COLORS_RYGU,
  COUNTRY_LIST,
  QUESTION_1,
} from "../../constants/constants.js";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card, Form } from "react-bootstrap";
import LoadingSpinner from "./../LoadingSpinner.js";
import { useAPI } from "../../ApiContext.js";
import { PieChartOptions } from "../../constants/BarChartOptions.js";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";
import { getCountryDataQ1 } from "../../Helper.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

export default function CountryPieChartQ1(props) {
  const { dashboardData } = useAPI();
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getChartData() {
    return {
      labels: COUNTRY_LIST.map((item) => item.value),
      datasets: [
        {
          label: "Country",
          data: COUNTRY_LIST.map((item) =>
            getCountryDataQ1(item.key, dashboardData.data, {
              countryFilterValue: props.countryFilterValue,
            })
          ),
          borderColor: colorPaletteState,
          backgroundColor: colorPaletteState,
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_1}</Card.Header>
      <Card.Body>
        {dashboardData.data && dashboardData.data.length > 0 ? (
          <div style={{ width: "100%", height: "60vh" }}>
            <Pie
              options={PieChartOptions}
              data={getChartData()}
              className={{ margin: "10px" }}
            />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}
