import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { Col, Row } from "react-bootstrap";
import ManufacturerPreferenceBarChartQ54 from "./ManufacturerPreferenceBarChartQ54";
import { PRICING_PREFERENCE_QUESTION } from "../../constants/constants";
import SwitchingManufacturerBarChartQ55 from "./SwitchingManufacturerBarChartQ55";

function PricingPreference() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        PRICING_PREFERENCE_QUESTION.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {filteredQuestionListContext.state.allQuestions && (
              <>
                <Col sm={12} md={6}>
                  <ManufacturerPreferenceBarChartQ54
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
                <Col sm={12} md={6}>
                  <SwitchingManufacturerBarChartQ55
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              </>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q54"
            ) && (
              <Col>
                <ManufacturerPreferenceBarChartQ54
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q55"
            ) && (
              <Col>
                <SwitchingManufacturerBarChartQ55
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default PricingPreference;
