import {
  ENDOSCOPIC_PROCEDURE_AMOUNT_LIST,
  EQUIPMENT_SETUP_LIST,
  INFLUENCING_CRITERIA_LIST_1,
  INFLUENCING_CRITERIA_LIST_2,
  INFLUENCING_CRITERIA_LIST_3,
  LIKELIHOOD_OF_ADOPTION_Q25_LIST,
  LIKELIHOOD_OF_ADOPTION_Q31_LIST,
  LIKELIHOOD_OF_ADOPTION_Q37_LIST,
  LIKELIHOOD_OF_ADOPTION_Q43_LIST,
  LIKELIHOOD_OF_ADOPTION_Q49_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q26_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q32_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q38_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q50_LIST,
  MANUFACTURER_PERFERENCE_Q54_LIST,
  PERCEIVED_BENEFITS_Q24_1_LIST,
  SERVICE_CONTRACT_LIST,
  SWITCHING_MANUFACTURER_Q55_LIST,
} from "./constants/constants";

export function getCountryDataQ1(idVal, data, filterContext) {
  let finalArray = data;

  //   filters
  finalArray = applyCountryFilter(filterContext, finalArray);
  // end of filters

  return (
    (finalArray.filter((obj) => obj.Q1 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function getRoleDataQ3(idVal, data, filterContext) {
  let finalArray = data;

  //   filters
  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }
  // end of filters

  return (
    (finalArray.filter((obj) => obj.Q3 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function getEquipmentAndSoftwareSelectionDataQ4(
  idVal,
  data,
  filterContext
) {
  let finalArray = data;

  //   filters
  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }
  // end of filters

  return (
    (finalArray.filter((obj) => obj.Q4 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function getPurchaseOrderDataQ5(idVal, data, filterContext) {
  let finalArray = data;

  //   filters
  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }
  // end of filters

  return (
    (finalArray.filter((obj) => obj.Q5 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function influencingCriteriaBarDataQ6_1(data, filterContext) {
  var finalArray = data;
  //   filters

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  finalArray = INFLUENCING_CRITERIA_LIST_1.map(
    (item, index) => finalArray.filter((obj) => obj.Q6_1 === item.key).length
  );

  //  end  filters

  return finalArray.map((item, index) => {
    return (
      (Number(item) /
        finalArray.reduce((total, next) => total + Number(next), 0)) *
      100
    ).toFixed(0);
  });
}

export function influencingCriteriaBarDataQ6_2(data, filterContext) {
  var finalArray = data;
  //   filters

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  finalArray = INFLUENCING_CRITERIA_LIST_2.map(
    (item, index) => finalArray.filter((obj) => obj.Q6_2 === item.key).length
  );

  //  end  filters

  return finalArray.map((item, index) => {
    return (
      (Number(item) /
        finalArray.reduce((total, next) => total + Number(next), 0)) *
      100
    ).toFixed(0);
  });
}

export function influencingCriteriaBarDataQ6_3(data, filterContext) {
  var finalArray = data;
  //   filters

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  finalArray = INFLUENCING_CRITERIA_LIST_3.map(
    (item, index) => finalArray.filter((obj) => obj.Q6_3 === item.key).length
  );

  //  end  filters

  return finalArray.map((item, index) => {
    return (
      (Number(item) /
        finalArray.reduce((total, next) => total + Number(next), 0)) *
      100
    ).toFixed(0);
  });
}

export function getFacilityTypeDataQ7(idVal, data, filterContext) {
  let finalArray = data;

  // #filter
  finalArray = finalArray.filter((item) => item.Q7);

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  // end  filter

  return (
    (finalArray.filter((obj) => obj.Q7 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function getFacilitySizeDataQ8(idVal, data, filterContext) {
  let finalArray = data;

  finalArray = finalArray.filter((item) => item.Q8);

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  return (
    (finalArray.filter((obj) => obj.Q8 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function getFacilityLocationDataQ9(idVal, data, filterContext) {
  let finalArray = data;

  finalArray = finalArray.filter((item) => item.Q9);

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  return (
    (finalArray.filter((obj) => obj.Q9 === idVal).length / finalArray.length) *
    100
  ).toFixed(0);
}

export function getAveragePercentageForEachOptionQ12(data, filterContext) {
  let finalArray = data;

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  finalArray = finalArray.filter((obj) => obj["noanswerQ12_r6"] === "0");

  finalArray = SERVICE_CONTRACT_LIST.map(
    (item, index) =>
      finalArray.reduce((total, next) => total + Number(next[item.key]), 0) /
      finalArray.length
  );

  return finalArray.map((item) => item.toFixed(0));
}

export function endoscopicProcedureAmountBarDataQ13(data, filterContext) {
  let finalArray = data;

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  finalArray = finalArray.filter(
    (obj) => obj["Q13x2r1c2"] === "0" || obj["Q13x2r2c2"] === "0"
  );

  finalArray = ENDOSCOPIC_PROCEDURE_AMOUNT_LIST.map(
    (item, index) =>
      finalArray.reduce((total, next) => total + Number(next[item.key]), 0) /
      finalArray.length
  );

  const totalSum = finalArray.reduce((total, next) => total + next);

  return finalArray.map((item, index) => {
    return ((item / totalSum) * 100).toFixed(0);
  });
}

export function getEquipmentSetupBarDataQ14(data, filterContext) {
  let finalArray = data;

  finalArray = applyCountryFilter(filterContext, finalArray);

  if (filterContext.roleFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  return EQUIPMENT_SETUP_LIST.map((item, index) =>
    (
      (finalArray.reduce((total, next) => total + Number(next[item.key]), 0) /
        finalArray.length) *
      100
    ).toFixed(0)
  );
}

export function getSoftwareCostPerYearDataQ16(data, filterContext) {
  var tempArray = [];
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  tempArray = resultArray.filter(
    (item) => item["Q16_1"] === "1" && item["noanswerQ16_2_r1"] === "0"
  );

  let poundCurrencyListConversionInEuro =
    tempArray
      .filter((item) => item["Q1"] === "2")
      .reduce((total, next) => total + Number(next.Q16_2), 0) * 1.13;

  let euroCurencyListConversion = tempArray
    .filter((item) => item["Q1"] === "1" || item["Q1"] === "3")
    .reduce((total, next) => total + Number(next.Q16_2), 0);

  let dollarCurencyListConversionInEuro =
    tempArray
      .filter((item) => item["Q1"] === "4")
      .reduce((total, next) => total + Number(next.Q16_2), 0) * 0.95;

  let yenCurencyListConversionInEuro =
    tempArray
      .filter((item) => item["Q1"] === "5")
      .reduce((total, next) => total + Number(next.Q16_2), 0) * 0.0064;

  var finalArray = [
    poundCurrencyListConversionInEuro,
    euroCurencyListConversion,
    dollarCurencyListConversionInEuro,
    yenCurencyListConversionInEuro,
  ].filter((item) => item);

  const currency = (
    finalArray.reduce((total, next) => total + Number(next), 0) /
    tempArray.length
  ).toFixed(0);

  return currency;
}
export function EndoscopyEquipmentAndServiceCostsDataQ17(
  item,
  data,
  filterContext
) {
  var tempArray = [];
  let resultArray = data;

  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  tempArray = resultArray.filter((obj) => obj[item.key1] === "0");

  let poundCurrencyListConversionInEuro =
    tempArray
      .filter((item) => item["Q1"] === "2")
      .reduce((total, next) => total + Number(next[item.key]), 0) * 1.13;

  let euroCurencyListConversion = tempArray
    .filter((item) => item["Q1"] === "1" || item["Q1"] === "3")
    .reduce((total, next) => total + Number(next[item.key]), 0);

  let dollarCurencyListConversionInEuro =
    tempArray
      .filter((item) => item["Q1"] === "4")
      .reduce((total, next) => total + Number(next[item.key]), 0) * 0.95;

  let yenCurencyListConversionInEuro =
    tempArray
      .filter((item) => item["Q1"] === "5")
      .reduce((total, next) => total + Number(next[item.key]), 0) * 0.0064;

  var finalArray = [
    poundCurrencyListConversionInEuro,
    euroCurencyListConversion,
    dollarCurencyListConversionInEuro,
    yenCurencyListConversionInEuro,
  ].filter((item) => item);

  return (
    finalArray.reduce((total, next) => total + Number(next), 0) /
    tempArray.length
  ).toFixed(0);
}

export function uncoverInefficiencyBarDataQ19(item, data, filterContext) {
  var tempArray = [];
  let resultArray = data;

  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  tempArray = resultArray.filter((obj) => obj[item.key1] === "0");

  var tempArray1 = resultArray.filter((item1) => item1[item.key]);

  if (item.key1) {
    return (
      tempArray.reduce((total, next) => total + Number(next[item.key]), 0) /
      tempArray.length
    ).toFixed(0);
  }

  return (
    tempArray1.reduce((total, next) => total + Number(next[item.key]), 0) /
    tempArray1.length
  ).toFixed(0);
}

export function perceivedBenefitsBarDataQ24_1(data) {
  const finalArray = [];
  let resultArray = data;

  PERCEIVED_BENEFITS_Q24_1_LIST.map((item, index) => {
    var test = resultArray
      .map((obj, index1) => obj[item.key])
      .filter((item) => item)
      .reduce((acc, current) => {
        if (current in acc) {
          acc[current]++;
        } else {
          acc[current] = 1;
        }
        return acc;
      }, {});

    finalArray.push(
      Object.values(test)
        .filter((obj) => obj)
        .map((item, index) =>
          (
            (item /
              Object.values(test).reduce((acc, current) => acc + current, 0)) *
            100
          ).toFixed(1)
        )
    );
  });

  return finalArray;
}

export function perceivedBenefitsBarDataQ24(item, data, filterContext) {
  let resultArray = data;

  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  var tempArray = resultArray.filter((item1) => item1[item.key1]);

  return (
    tempArray.reduce((total, next) => total + Number(next[item.key1]), 0) /
    tempArray.length
  ).toFixed(0);
}

export function likelihoodOfAdoptionBarDataQ25(data, filterContext) {
  let resultArray = data;

  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q25);

  var test = LIKELIHOOD_OF_ADOPTION_Q25_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q25 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function likelihoodOfRecommendationBarDataQ26(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q26);

  var test = LIKELIHOOD_OF_RECOMMENDATION_Q26_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q26 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function willingnessToPayHistogramDataQ27(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q27);
  const x_values = [10, 30, 50, 70, 90, 110];

  var tempArray = resultArray.filter((obj) => obj["noanswerQ27_r1"] === "0");

  const y_vals = tempArray.map((item, index) => Number(item.Q27));

  if (!y_vals.length) {
    return;
  }

  var test = histogram(y_vals, 20);
  var data = x_values.map((k, i) => ({ x: k, y: test[i] }));
  return data;
}
function histogram(X, binRange) {
  //inclusive of the first number
  var max = Math.max(...X);
  var min = Math.min(...X);
  var len = max - min + 1;
  var numberOfBins = Math.ceil(len / binRange);
  var bins = new Array(numberOfBins).fill(0);
  //-min to normalise values for the array
  X.forEach((x) => bins[Math.floor(x / binRange)]++);
  return bins;
}

export function serviceContractBarDataQ28(item, data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  var tempArray = resultArray.filter((obj) => obj[item.key]);

  if (!tempArray.length) {
    return;
  }

  return (
    (tempArray.filter((obj) => obj[item.key] === "1").length /
      tempArray.length) *
    100
  ).toFixed(0);
}

export function perceivedBenefitsBarDataQ30_1(item, data, filterContext) {
  let resultArray = data;

  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  var tempArray = resultArray.filter((item1) => item1[item.key1]);

  return (
    tempArray.reduce((total, next) => total + Number(next[item.key1]), 0) /
    tempArray.length
  ).toFixed(0);
}

export function likelihoodOfAdoptionBarDataQ31(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q31);

  var test = LIKELIHOOD_OF_ADOPTION_Q31_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q31 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function likelihoodOfRecommendationBarDataQ32(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q32);

  var test = LIKELIHOOD_OF_RECOMMENDATION_Q32_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q32 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getPerceivedBenefitsBarChartQ36_1(item, data, filterContext) {
  let resultArray = data;

  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  var tempArray = resultArray.filter((item1) => item1[item.key1]);

  return (
    tempArray.reduce((total, next) => total + Number(next[item.key1]), 0) /
    tempArray.length
  ).toFixed(0);
}

export function getLikelihoodOfAdoptionBarDataQ37(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q37);

  var test = LIKELIHOOD_OF_ADOPTION_Q37_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q37 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getLikelihoodOfRecommendationBarChartQ38(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q38);

  var test = LIKELIHOOD_OF_RECOMMENDATION_Q38_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q38 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getPerceivedBenefitsBarChartQ42_1(item, data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  var tempArray = resultArray.filter((item1) => item1[item.key1]);

  return (
    tempArray.reduce((total, next) => total + Number(next[item.key1]), 0) /
    tempArray.length
  ).toFixed(0);
}

export function getLikelihoodOfAdoptionBarChartQ43(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q43);

  var test = LIKELIHOOD_OF_ADOPTION_Q43_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q43 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getLikelihoodOfRecommendationBarChartQ44(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q44);

  var test = LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q44 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getPerceivedBenefitsBarChartQ48_1(item, data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  var tempArray = resultArray.filter((item1) => item1[item.key1]);

  return (
    tempArray.reduce((total, next) => total + Number(next[item.key1]), 0) /
    tempArray.length
  ).toFixed(0);
}

export function getLikelihoodOfAdoptionBarChartQ49(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q49);

  var test = LIKELIHOOD_OF_ADOPTION_Q49_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q49 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getLikelihoodOfRecommendationBarChartQ50(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q50);

  var test = LIKELIHOOD_OF_RECOMMENDATION_Q50_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q50 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getManufacturerPreferenceBarChartQ54(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q54);

  var test = MANUFACTURER_PERFERENCE_Q54_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q54 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

export function getManufacturerPreferenceBarChartQ55(data, filterContext) {
  let resultArray = data;
  if (filterContext.countryFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }

  if (filterContext.roleFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q3 === filterContext.roleFilterValue
    );
  }

  if (filterContext.facilityTypeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q7 === filterContext.facilityTypeFilterValue
    );
  }

  if (filterContext.facilitySizeFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q8 === filterContext.facilitySizeFilterValue
    );
  }

  if (filterContext.facilityLocationFilterValue) {
    resultArray = resultArray.filter(
      (item) => item.Q9 === filterContext.facilityLocationFilterValue
    );
  }

  resultArray = resultArray.filter((item) => item.Q55);

  var test = SWITCHING_MANUFACTURER_Q55_LIST.map(
    (item, index) =>
      resultArray.filter((obj) => obj.Q55 === item.realValue).length
  );

  return test.map((item, index) => {
    return ((Number(item) / resultArray.length) * 100).toFixed(0);
  });
}

function applyCountryFilter(filterContext, finalArray) {
  if (filterContext.countryFilterValue) {
    finalArray = finalArray.filter(
      (item) => item.Q1 === filterContext.countryFilterValue
    );
  }
  return finalArray;
}

export function getTotalOnlyCount(data) {
  let obj = data;

  const total = ["qualified", "terminates", "overquota"];

  let totalArray = obj.rows.filter((item) => total.includes(item.formula));

  totalArray = totalArray.map((item) => item.cells[0].formatted);

  let totalSum = totalArray.reduce((accum, next) => accum + next, 0);

  return totalSum;
}

export function getCountByKey(key, data) {
  let obj = data;

  let finalArray = obj.rows.filter((item, index) => item.formula === key)[0];

  return finalArray.cells[0].formatted;
}
export function getSurveyCompletionTime(key, data) {
  let obj = data;

  let finalArray = obj.rows.filter((item, index) => item.formula === key)[0];

  return finalArray.cells[0].formatted;
}
