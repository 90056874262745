export const BarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0, // Add left padding
      right: 0, // Add right padding
      top: 50, // Add top padding
      bottom: 5, // Add bottom padding
    },
  },
  plugins: {
    legend: {
      display: false,
      title: { display: true, padding: 10 },
    },
    datalabels: {
      anchor: "center",
      align: "center",
      color: "white",
      font: {
        weight: "bold",
        size: 13,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      display: false,
    },
  },
};

export const PieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  maintainAspectRatio: false,
  layout: {
    padding: 30,
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      title: { display: true, padding: 10 },
      onClick: null, // Disable legend item click event
      labels: {
        font: {
          //size: 12,
          //weight: "bold",
        },
      },
    },
    datalabels: {
      formatter: (value, context) => {
        if (context.dataset.data[context.dataIndex] > 0) {
          return value + " %";
        } else {
          return "";
        }
      },
      display: true,
      anchor: "center",
      align: "end",
      color: "white",
      font: {
        size: 13,
        weight: "bold",
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return context.formattedValue + "%";
        },
      },
    },
  },
  elements: {
    arc: {
      spacing: 2,
    },
  },
};
