import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { Col, Row } from "react-bootstrap";
import PerceivedBenefitsBarChartQ30 from "./PerceivedBenefitsBarChartQ30";
import PerceivedBenefitsBarChartQ30_1 from "./PerceivedBenefitsBarChartQ30_1";
import LikelihoodOfAdoptionBarChartQ31 from "./LikelihoodOfAdoptionBarChartQ31";
import LikelihoodOfRecommendationBarChartQ32 from "./LikelihoodOfRecommendationBarChartQ32";
import WillingnessToPayHistogramChartQ33 from "./WillingnessToPayHistogramChartQ33";
import { AUTO_MAINTENANCE_QUESTIONS } from "../../constants/constants";

function AutoMaintenance() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        AUTO_MAINTENANCE_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q30"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ30
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q30"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ30_1
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {filteredQuestionListContext.state.allQuestions && (
              <>
                <Col sm={12} md={6}>
                  <LikelihoodOfAdoptionBarChartQ31
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
                <Col sm={12} md={6}>
                  <LikelihoodOfRecommendationBarChartQ32
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              </>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q31"
            ) && (
              <Col>
                <LikelihoodOfAdoptionBarChartQ31
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q32"
            ) && (
              <Col>
                <LikelihoodOfRecommendationBarChartQ32
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q33"
              )) && (
              <Col>
                <WillingnessToPayHistogramChartQ33
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default AutoMaintenance;
