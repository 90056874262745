import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { SUPPORTBOT_QUESTIONS } from "../../constants/constants";
import { Col, Row } from "react-bootstrap";
import PerceivedBenefitsBarChartQ42_1 from "./PerceivedBenefitsBarChartQ42_1";
import LikelihoodOfAdoptionBarChartQ43 from "./LikelihoodOfAdoptionBarChartQ43";
import LikelihoodOfRecommendationBarChartQ44 from "./LikelihoodOfRecommendationBarChartQ44";
import WillingnessToPayHistogramChartQ45 from "./WillingnessToPayHistogramChartQ45";
import PerceivedBenefitsBarChart42 from "./PerceivedBenefitsBarChartQ42";

function SupportBot() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        SUPPORTBOT_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q42"
              )) && (
              <Col>
                <PerceivedBenefitsBarChart42
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q42"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ42_1
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {filteredQuestionListContext.state.allQuestions && (
              <>
                <Col sm={12} md={6}>
                  <LikelihoodOfAdoptionBarChartQ43
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
                <Col sm={12} md={6}>
                  <LikelihoodOfRecommendationBarChartQ44
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              </>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q43"
            ) && (
              <Col>
                <LikelihoodOfAdoptionBarChartQ43
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q44"
            ) && (
              <Col>
                <LikelihoodOfRecommendationBarChartQ44
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q45"
              )) && (
              <Col>
                <WillingnessToPayHistogramChartQ45
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default SupportBot;
