import { Card, Form } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useAPI } from "../../ApiContext";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App";
import LoadingSpinner from "../LoadingSpinner";

function Summary() {
  const { dashboardData } = useAPI();
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getData(key, data) {
    let obj = data;

    const total = ["qualified", "terminates", "overquota"];

    let qualified = obj.rows.filter((item) => item.formula === key)[0];

    let totalArray = obj.rows.filter((item) => total.includes(item.formula));

    totalArray = totalArray.map((item) => item.cells[0].formatted);

    let totalSum = totalArray.reduce((accum, next) => accum + next, 0);

    return ((qualified.cells[0].formatted / totalSum) * 100).toFixed(0);
  }

  const data = {
    labels: ["Qualified", "Terminates", "Overquota"],
    datasets: [
      {
        data: ["qualified", "terminates", "overquota"].map((key) =>
          getData(key, dashboardData.summary)
        ),
        backgroundColor: colorPaletteState,
        hoverBackgroundColor: colorPaletteState,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0, // Add left padding
        right: 0, // Add right padding
        top: 20, // Add top padding
        bottom: 5, // Add bottom padding
      },
    },
    cutoutPercentage: 50, // Adjust this to control the size of the hole in the doughnut
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem, data) => {
            return tooltipItem.raw;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        title: { display: true, padding: 10 },
        onClick: null, // Disable legend item click event
        labels: {
          font: {
            //size: 12,
            //weight: "bold",
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          if (context.dataset.data[context.dataIndex] > 0) {
            return value + " %";
          } else {
            return "";
          }
        },
        anchor: "center",
        align: "center",
        color: "white",
        font: {
          weight: "bold",
          size: 13,
        },
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        {dashboardData.summary && dashboardData.summary.rows.length > 0 ? (
          <div style={{ width: "100%", height: "60vh" }}>
            <Doughnut data={data} options={options} />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}

export default Summary;
