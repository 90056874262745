// replace the user pool region, id, and app client id details
// export default {
//   REGION: "ap-southeast-2",
//   USER_POOL_ID: "ap-southeast-2_SN1ODxNfo",
//   USER_POOL_APP_CLIENT_ID: "6dd4461jm81ie4d0uduqaf7u0u",
// };

export default {
  REGION: "ap-southeast-2",
  USER_POOL_ID: "ap-southeast-2_m0ddvgurH",
  USER_POOL_APP_CLIENT_ID: "2mcddm1k2vvpptc4m9phk3gja3",
};
