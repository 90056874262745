import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../LoadingSpinner.js";
import { QUESTION_16 } from "../../constants/constants.js";
import { Card } from "react-bootstrap";
import { useAPI } from "../../ApiContext.js";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";
import { getSoftwareCostPerYearDataQ16 } from "../../Helper.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function SotfwareCostPerYearBarChartQ16(props) {
  const { dashboardData } = useAPI();

  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 30,
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value) => {
            return Number(value).toLocaleString("en-GB", {
              style: "currency",
              currency: "EUR",
            });
          },
          anchor: "end",
          align: "top",
          color: "grey",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          display: false,
        },
      },
    };
  }

  function getChartData() {
    return {
      labels: ["Average value of software cost in Euro (€)"],
      datasets: [
        {
          label: "Tool landscape: Asset management",
          data: [
            getSoftwareCostPerYearDataQ16(
              dashboardData.data,
              // ).toLocaleString("en-GB", {
              //   style: "currency",
              //   currency: "EUR",
              //   minimumFractionDigits: 0,
              //   maximumFractionDigits: 0,
              {
                countryFilterValue: props.countryFilterValue,
                roleFilterValue: props.roleFilterValue,
              }
            ),
          ],
          borderColor: colorPaletteState[0],
          backgroundColor: colorPaletteState[0],
          height: 70,
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_16}</Card.Header>
      <Card.Body>
        {dashboardData.data && dashboardData.data.length > 0 ? (
          <div style={{ width: "100%", height: "80vh" }}>
            <Bar options={getOptions()} data={getChartData()} />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}
export default SotfwareCostPerYearBarChartQ16;
