import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { OPSOPTI_QUESTIONS } from "../../constants/constants";
import { Col, Row } from "react-bootstrap";
import PerceivedBenefitsBarChartQ24_1 from "./PerceivedBenefitsBarChartQ24_1";
import PerceivedBenefitsBarChartQ24 from "./PerceivedBenefitsBarChartQ24";
import LikelihoodOfAdoptionBarChartQ25 from "./LikelihoodOfAdoptionBarChartQ25";
import LikelihoodOfRecommendationBarChartQ26 from "./LikelihoodOfRecommendationBarChartQ26";
import WillingnessToPayHistogramChartQ27 from "./WillingnessToPayHistogramChartQ27";
import ServiceContractBarChartQ28 from "./ServiceContractBarChartQ28";

function OpsOpti() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        OPSOPTI_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q24"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ24_1
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q24"
              )) && (
              <Col>
                <PerceivedBenefitsBarChartQ24
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {filteredQuestionListContext.state.allQuestions && (
              <>
                <Col sm={12} md={6}>
                  <LikelihoodOfAdoptionBarChartQ25
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
                <Col sm={12} md={6}>
                  <LikelihoodOfRecommendationBarChartQ26
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              </>
            )}

            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q25"
            ) && (
              <Col>
                <LikelihoodOfAdoptionBarChartQ25
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
            {filteredQuestionListContext.state.filteredQuestions.includes(
              "Q26"
            ) && (
              <Col>
                <LikelihoodOfRecommendationBarChartQ26
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q27"
              )) && (
              <Col>
                <WillingnessToPayHistogramChartQ27
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q28"
              )) && (
              <Col>
                <ServiceContractBarChartQ28
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default OpsOpti;
