import { Card, Col, Form, Row } from "react-bootstrap";
import {
  getCountByKey,
  getSurveyCompletionTime,
  getTotalOnlyCount,
} from "../../Helper";
import { useAPI } from "../../ApiContext";

function OverallSummary() {
  const { dashboardData } = useAPI();

  return (
    <Card>
      <Card.Body>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="9">
            <b>Total (Qualified + Terminates + Overquota):</b>
          </Form.Label>
          <Col sm="3">
            <Form.Label column>
              {getTotalOnlyCount(dashboardData.summary)}
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="9">
            <b>Qualified:</b>
          </Form.Label>
          <Col sm="3">
            <Form.Label column>
              {getCountByKey("qualified", dashboardData.summary)}
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="9">
            <b>Terminates:</b>
          </Form.Label>
          <Col sm="3">
            <Form.Label>
              {getCountByKey("terminates", dashboardData.summary)}
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="9">
            <b>Overquota:</b>
          </Form.Label>
          <Col sm="3">
            <Form.Label>
              {getCountByKey("overquota", dashboardData.summary)}
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="9">
            <b>Completion Time (average):</b>
          </Form.Label>
          <Col sm="3">
            <Form.Label column>
              {getSurveyCompletionTime("completionTime", dashboardData.summary)}{" "}
              min
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="9">
            <b>Completion Time (median):</b>
          </Form.Label>
          <Col sm="3">
            <Form.Label column>
              {getSurveyCompletionTime(
                "completionTimeMedian",
                dashboardData.summary
              )}{" "}
              min
            </Form.Label>
          </Col>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default OverallSummary;
