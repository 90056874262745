import { Col, Container, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import QualifedPieChart from "./Summary/QualifedPieChart";
import PartialPieChart from "./Summary/PartialPieChart";
import TerminatesPieChart from "./Summary/TerminatesPieChart";
import OverQuotaPieChart from "./Summary/OverQuotaPieChart";
import ClickThroughPieChart from "./Summary/ClickThroughPieChart";
import OverallSummary from "./Summary/OverallSummary";
import Summary from "./Summary/Summary";

function DashboardSummary() {
  return (
    <Container>
      <div style={{ margin: "40px 0" }}>
        <Tabs defaultActiveKey="overall" className="mb-3">
          <Tab eventKey="overall" title="Overall">
            <Row>
              <Col>
                <Summary />
              </Col>
              <Col>
                <OverallSummary />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="qualified" title="Qualified">
            <Row>
              <Col>
                <QualifedPieChart />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="clickthrough" title="ClickThrough">
            <Row>
              <Col>
                <ClickThroughPieChart />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="partial" title="Partial">
            <Row>
              <Col>
                <PartialPieChart />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="terminates" title="Terminates">
            <Row>
              <Col>
                <TerminatesPieChart />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="overquota" title="Overquota">
            <Row>
              <Col>
                <OverQuotaPieChart />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
}

export default DashboardSummary;
