import React, { useContext, useState } from "react";
import { FiDownload, FiLogOut } from "react-icons/fi";
import { FaHome, FaFilter } from "react-icons/fa";
import { CgColorPicker } from "react-icons/cg";
import pptxgen from "pptxgenjs";
import { IconContext } from "react-icons";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { ToastContainer, toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { FaDisplay } from "react-icons/fa6";
import { Link } from "react-router-dom";

import {
  COLORS_RYGU,
  COUNTRY_LIST,
  ENDOSCOPIC_EQUIPMENT_AND_SERVICE_COST_LIST,
  ENDOSCOPIC_PROCEDURE_AMOUNT_LIST,
  EQUIPMENT_AND_SOFTWARE_SELECTION_LIST,
  EQUIPMENT_SETUP_LIST,
  FACILITY_LOCATION_LIST,
  FACILITY_SIZE_LIST,
  FACILITY_TYPE_LIST,
  INFLUENCING_CRITERIA_LIST_1,
  INFLUENCING_CRITERIA_LIST_2,
  INFLUENCING_CRITERIA_LIST_3,
  LIKELIHOOD_OF_ADOPTION_Q25_LIST,
  LIKELIHOOD_OF_ADOPTION_Q31_LIST,
  LIKELIHOOD_OF_ADOPTION_Q37_LIST,
  LIKELIHOOD_OF_ADOPTION_Q43_LIST,
  LIKELIHOOD_OF_ADOPTION_Q49_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q26_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q32_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q38_LIST,
  LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST,
  MANUFACTURER_PERFERENCE_Q54_LIST,
  PERCEIVED_BENEFITS_Q24_1_LIST,
  PERCEIVED_BENEFITS_Q24_LIST,
  PERCEIVED_BENEFITS_Q30_1_LIST,
  PERCEIVED_BENEFITS_Q36_1_LIST,
  PERCEIVED_BENEFITS_Q42_1_LIST,
  PERCEIVED_BENEFITS_Q48_1_LIST,
  PURCHASE_ORDER_LIST,
  QUESTION_1,
  QUESTION_12,
  QUESTION_13,
  QUESTION_14,
  QUESTION_16,
  QUESTION_17,
  QUESTION_19,
  QUESTION_24,
  QUESTION_24_1,
  QUESTION_25,
  QUESTION_26,
  QUESTION_27,
  QUESTION_28,
  QUESTION_3,
  QUESTION_30,
  QUESTION_31,
  QUESTION_32,
  QUESTION_36,
  QUESTION_37,
  QUESTION_38,
  QUESTION_4,
  QUESTION_42,
  QUESTION_43,
  QUESTION_44,
  QUESTION_48,
  QUESTION_49,
  QUESTION_5,
  QUESTION_50,
  QUESTION_54,
  QUESTION_55,
  QUESTION_6_1,
  QUESTION_6_2,
  QUESTION_6_3,
  QUESTION_7,
  QUESTION_8,
  QUESTION_9,
  ROLE_LIST,
  SERVICE_CONTRACT_LIST,
  SERVICE_CONTRACT_OPS_OPTI_LIST,
  SWITCHING_MANUFACTURER_Q55_LIST,
  UNCOVER_INEFFICIENCY_LIST,
} from "../constants/constants";
import { useAPI } from "../ApiContext";
import { ColorPaletteContext, FilterContext } from "../App";
import { STARLABS_LOGO_SM } from "../images/media.mjs";
import ColorPickerModal from "./Modal/ColorPickerModal";
import FilterModal from "./Modal/FilterModal";
import {
  EndoscopyEquipmentAndServiceCostsDataQ17,
  endoscopicProcedureAmountBarDataQ13,
  getAveragePercentageForEachOptionQ12,
  getCountryDataQ1,
  getEquipmentAndSoftwareSelectionDataQ4,
  getEquipmentSetupBarDataQ14,
  getFacilityLocationDataQ9,
  getFacilitySizeDataQ8,
  getFacilityTypeDataQ7,
  getLikelihoodOfAdoptionBarChartQ43,
  getLikelihoodOfAdoptionBarChartQ49,
  getLikelihoodOfAdoptionBarDataQ37,
  getLikelihoodOfRecommendationBarChartQ38,
  getLikelihoodOfRecommendationBarChartQ44,
  getLikelihoodOfRecommendationBarChartQ50,
  getManufacturerPreferenceBarChartQ54,
  getManufacturerPreferenceBarChartQ55,
  getPerceivedBenefitsBarChartQ36_1,
  getPerceivedBenefitsBarChartQ42_1,
  getPerceivedBenefitsBarChartQ48_1,
  getPurchaseOrderDataQ5,
  getRoleDataQ3,
  getSoftwareCostPerYearDataQ16,
  influencingCriteriaBarDataQ6_1,
  influencingCriteriaBarDataQ6_2,
  influencingCriteriaBarDataQ6_3,
  likelihoodOfAdoptionBarDataQ25,
  likelihoodOfAdoptionBarDataQ31,
  likelihoodOfRecommendationBarDataQ26,
  likelihoodOfRecommendationBarDataQ32,
  perceivedBenefitsBarDataQ24,
  perceivedBenefitsBarDataQ24_1,
  perceivedBenefitsBarDataQ30_1,
  serviceContractBarDataQ28,
  uncoverInefficiencyBarDataQ19,
  willingnessToPayHistogramDataQ27,
} from "../Helper";

export default function Navigation() {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const filterContext = useContext(FilterContext);

  const { colorPaletteState, updateColorPaletteState } = colorPaletteContext;

  const [colorList, setColorList] = useState(
    colorPaletteState.map((color, index) => ({
      color: color,
      showHexColorPicker: false,
    }))
  );

  const [show, setShow] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseFilterModal = () => setShowFilters(false);
  const handleFiltersShow = () => setShowFilters(true);

  const handleColorAdd = () => {
    setColorList([...colorList, { color: "" }]);
  };

  const handleColorRemove = (index) => {
    const list = [...colorList];
    list.splice(index, 1);
    setColorList(list);
  };

  const handleColorChange = (index, color) => {
    setColorList(
      colorList.map((item, index1) =>
        index === index1 ? { ...item, color } : item
      )
    );
  };

  const handleFocus = (index) => {
    setColorList(
      colorList.map((item, index1) =>
        index === index1 ? { ...item, showHexColorPicker: true } : item
      )
    );
  };

  const handleBlur = (index) => {
    setColorList(
      colorList.map((item, index1) =>
        index === index1 ? { ...item, showHexColorPicker: false } : item
      )
    );
  };

  const handleSaveChanges = () => {
    if (colorList.some((item) => item.color === "")) {
      toast.error("Please ensure to add color entry(ies)!", {
        autoClose: 2000,
        hideProgressBar: true,
      });

      return;
    }

    updateColorPaletteState(colorList.map((item) => item.color));
    handleClose();
  };

  const handleRevert = () => {
    updateColorPaletteState(COLORS_RYGU);
    setColorList(
      COLORS_RYGU.map((color, index) => ({
        color: color,
        showHexColorPicker: false,
      }))
    );
    handleClose();
  };

  const { dashboardData } = useAPI();

  //#region Question 1
  const countryPieChart = [
    {
      name: "Country",
      labels: COUNTRY_LIST.map((item) => item.value),
      values: COUNTRY_LIST.map((item) =>
        getCountryDataQ1(item.key, dashboardData.data, filterContext)
      ),
    },
  ];
  //#endregion

  //#region Question 3

  const rolePieChart = [
    {
      name: "Role",
      labels: ROLE_LIST.map((item) => item.value),
      values: ROLE_LIST.map((item) =>
        getRoleDataQ3(item.key, dashboardData.data, filterContext)
      ),
    },
  ];
  //#endregion

  //#region Question 4
  function getEquipmentAndSoftwareSelectionData() {
    return EQUIPMENT_AND_SOFTWARE_SELECTION_LIST.map((item) =>
      getEquipmentAndSoftwareSelectionDataQ4(
        item.key,
        dashboardData.data,
        filterContext
      )
    );
  }
  //#endregion

  //#region Question 5

  function getPurchaseOrderData() {
    return PURCHASE_ORDER_LIST.map((item) =>
      getPurchaseOrderDataQ5(item.key, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 6_1
  function getInfluencingCriteriaBarDataQ6_1() {
    return influencingCriteriaBarDataQ6_1(dashboardData.data, filterContext);
  }
  //#endregion

  //#region Question 6_2
  function getInfluencingCriteriaBarDataQ6_2() {
    return influencingCriteriaBarDataQ6_2(dashboardData.data, filterContext);
  }
  //#endregion

  //#region Question 6_3

  function getInfluencingCriteriaBarDataQ6_3() {
    return influencingCriteriaBarDataQ6_3(dashboardData.data, filterContext);
  }
  //#endregion

  //#region Question 7
  function getFacilityTypeData() {
    return FACILITY_TYPE_LIST.map((item) =>
      getFacilityTypeDataQ7(item.key, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 8
  function getFacilitySizeData() {
    return FACILITY_SIZE_LIST.map((item) =>
      getFacilitySizeDataQ8(item.key, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 9
  function getFacilityLocationData() {
    return FACILITY_LOCATION_LIST.map((item) =>
      getFacilityLocationDataQ9(item.key, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 12
  function getAveragePercentageForEachOptionData() {
    return getAveragePercentageForEachOptionQ12(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 13
  function getEndoscopicProcedureAmountBarData() {
    return endoscopicProcedureAmountBarDataQ13(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 14
  function getEquipmentSetupBarData() {
    return getEquipmentSetupBarDataQ14(dashboardData.data, filterContext);
  }
  //#endregion
  //#endregion
  //#region Question 16
  function getSoftwareCostPerYearData() {
    return getSoftwareCostPerYearDataQ16(dashboardData.data, filterContext);
  }
  //#endregion

  //#region Question 17
  function EndoscopyEquipmentAndServiceCostsData() {
    return ENDOSCOPIC_EQUIPMENT_AND_SERVICE_COST_LIST.map((item, index) =>
      EndoscopyEquipmentAndServiceCostsDataQ17(
        item,
        dashboardData.data,
        filterContext
      )
    );
  }
  //#endregion

  //#region Question 19
  function getUncoverInefficiencyBarData() {
    return UNCOVER_INEFFICIENCY_LIST.map((item, index) =>
      uncoverInefficiencyBarDataQ19(item, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 24
  function getPerceivedBenefitsBarDataQ24() {
    return PERCEIVED_BENEFITS_Q24_LIST.map((item, index) =>
      perceivedBenefitsBarDataQ24(item, dashboardData.data, filterContext)
    );
  }
  //#endregion
  //#region Question 24_1
  function getPerceivedBenefitsBarDataQ24_1() {
    return PERCEIVED_BENEFITS_Q24_1_LIST.map((item, index) =>
      perceivedBenefitsBarDataQ24_1(dashboardData.data)
    );
  }
  //#endregion
  //#region Question 25
  function getLikelihoodOfAdoptionBarData() {
    return likelihoodOfAdoptionBarDataQ25(dashboardData.data, filterContext);
  }
  //#endregion
  //#region Question 26
  function getLikelihoodOfRecommendationBarData() {
    return likelihoodOfRecommendationBarDataQ26(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 27
  function getWillingnessToPayHistogramData() {
    return willingnessToPayHistogramDataQ27(dashboardData.data, filterContext);
  }
  //#endregion

  //#region Question 28
  function getServiceContractBarData() {
    return SERVICE_CONTRACT_OPS_OPTI_LIST.map((item, index) =>
      serviceContractBarDataQ28(item, dashboardData.data, filterContext)
    );
  }
  //#endregion
  //#region Question 33_1
  function getPerceivedBenefitsBarDataQ30_1() {
    return PERCEIVED_BENEFITS_Q30_1_LIST.map((item, index) =>
      perceivedBenefitsBarDataQ30_1(item, dashboardData.data, filterContext)
    );
  }
  //#endregion
  //#region Question 31
  function getlikelihoodOfAdoptionBarDataQ31() {
    return likelihoodOfAdoptionBarDataQ31(dashboardData.data, filterContext);
  }
  //#endregion

  //#region Question 32
  function getLikelihoodOfRecommendationBarDataQ32() {
    return likelihoodOfRecommendationBarDataQ32(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 36_1
  function getPerceivedBenefitsBarDataQ36_1() {
    return PERCEIVED_BENEFITS_Q36_1_LIST.map((item, index) =>
      getPerceivedBenefitsBarChartQ36_1(item, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 37
  function getLikelihoodOfAdoptionBarDataQ37ForChart() {
    return getLikelihoodOfAdoptionBarDataQ37(dashboardData.data, filterContext);
  }
  //#endregion
  //#region Question 38
  function getLikelihoodOfRecommendationBarDataQ38() {
    return getLikelihoodOfRecommendationBarChartQ38(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 42_1
  function getPerceivedBenefitsBarDataQ42_1() {
    return PERCEIVED_BENEFITS_Q42_1_LIST.map((item, index) =>
      getPerceivedBenefitsBarChartQ42_1(item, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 43
  function getLikelihoodOfAdoptionBarDataQ43() {
    return getLikelihoodOfAdoptionBarChartQ43(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion
  //#region Question 44
  function getLikelihoodOfRecommendationBarDataQ44() {
    return getLikelihoodOfRecommendationBarChartQ44(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 48_1
  function getPerceivedBenefitsBarDatatQ48_1() {
    return PERCEIVED_BENEFITS_Q48_1_LIST.map((item, index) =>
      getPerceivedBenefitsBarChartQ48_1(item, dashboardData.data, filterContext)
    );
  }
  //#endregion

  //#region Question 49
  function getLikelihoodOfAdoptionBarDataQ49() {
    return getLikelihoodOfAdoptionBarChartQ49(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  //#region Question 50
  function getLikelihoodOfRecommendationBarDataQ50() {
    return getLikelihoodOfRecommendationBarChartQ50(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion
  //#region Question 54
  function getManufacturerPreferenceBarDataQ54() {
    return getManufacturerPreferenceBarChartQ54(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion
  //#region Question 55
  function getManufacturerPreferenceBarDataQ55() {
    return getManufacturerPreferenceBarChartQ55(
      dashboardData.data,
      filterContext
    );
  }
  //#endregion

  function getSlide1(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    // TOP-LEFT
    slide.addChart(pptx.ChartType.pie, countryPieChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      chartArea: { fill: { color: "F1F1F1" } },
      chartColors: COLORS_RYGU,
      dataBorder: { pt: 1, color: "F1F1F1" },
      legendFontFace: "Courier New",
      showLegend: true,
      showPercent: true,
      //showValue: true,
      dataLabelColor: "F1F1F1",
      dataLabelFontSize: 8,
      dataLabelPosition: "bestFit",
      legendPos: "r",
      showTitle: true,
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      title: `${QUESTION_1}`,
    });

    // TOP-MIDDLE
    slide.addChart(pptx.charts.PIE, rolePieChart, {
      x: 7.0,
      y: 0.6,
      w: 6,
      h: "80%",
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: "1", color: "F1F1F1" },
      chartColors: COLORS_RYGU,
      dataLabelColor: "f2f9fc",
      dataLabelFontSize: 8,
      showPercent: true,
      showLegend: true,
      legendPos: "r",
      showTitle: true,
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      legendFontSize: 8,
      title: `${QUESTION_3}`,
    });
  }

  function getSlide2(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let equipmentAndSoftwareSelectionBarChart = [
      {
        name: "Equipment And Software Selection",
        labels: EQUIPMENT_AND_SOFTWARE_SELECTION_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getEquipmentAndSoftwareSelectionData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, equipmentAndSoftwareSelectionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_4}`,
    });

    let purchaseOrderBarChart = [
      {
        name: "Purchase Order",
        labels: PURCHASE_ORDER_LIST.map((item) => item.value.join(" ")),
        values: getPurchaseOrderData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, purchaseOrderBarChart, {
      x: 7.0,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_5}`,
    });
  }

  function getSlide3(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let influencingCriteriaBarChart_1 = [
      {
        name: "influencing Criteria Bar Chart",
        labels: INFLUENCING_CRITERIA_LIST_1.map((item, index) =>
          item.value.join(" ")
        ),
        values: getInfluencingCriteriaBarDataQ6_1(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, influencingCriteriaBarChart_1, {
      x: 0.5,
      y: 0.6,
      w: 4,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_6_1}`,
    });

    let influencingCriteriaBarChartQ6_2 = [
      {
        name: "influencing Criteria Bar Chart",
        labels: INFLUENCING_CRITERIA_LIST_2.map((item, index) =>
          item.value.join(" ")
        ),
        values: getInfluencingCriteriaBarDataQ6_2(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, influencingCriteriaBarChartQ6_2, {
      x: 4.8,
      y: 0.6,
      w: 4,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_6_2}`,
    });

    let influencingCriteriaBarChartQ6_3 = [
      {
        name: "influencing Criteria Bar Chart",
        labels: INFLUENCING_CRITERIA_LIST_3.map((item, index) =>
          item.value.join(" ")
        ),
        values: getInfluencingCriteriaBarDataQ6_3(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, influencingCriteriaBarChartQ6_3, {
      x: 9,
      y: 0.6,
      w: 4,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_6_3}`,
    });
  }

  function getSlide4(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let FacilityTypeDataQ7 = [
      {
        name: "influencing Criteria Bar Chart",
        labels: FACILITY_TYPE_LIST.map((item, index) => item.value.join(" ")),
        values: getFacilityTypeData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, FacilityTypeDataQ7, {
      x: 0.5,
      y: 0.6,
      w: 4,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_7}`,
    });

    let FacilitySizeData = [
      {
        name: "Facility Size  Bar Chart",
        labels: FACILITY_SIZE_LIST.map((item, index) => item.value.join(" ")),
        values: getFacilitySizeData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, FacilitySizeData, {
      x: 4.8,
      y: 0.6,
      w: 4,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_8}`,
    });

    let FacilityLocationData = [
      {
        name: "Influencing Criteria Bar Chart",
        labels: FACILITY_LOCATION_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getFacilityLocationData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, FacilityLocationData, {
      x: 9,
      y: 0.6,
      w: 4,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_9}`,
    });
  }

  function getSlide5(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let serviceContractList = [
      {
        name: "service Contract List",
        labels: SERVICE_CONTRACT_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getAveragePercentageForEachOptionData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, serviceContractList, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataTableFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_12}`,
    });
  }

  function getSlide6(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let EndoscopicProcedureAmount = [
      {
        name: "ENDOSCOPIC_PROCEDURE_AMOUNT_LIST",
        labels: ENDOSCOPIC_PROCEDURE_AMOUNT_LIST.map(
          (item, index) => item.value
        ),
        values: getEndoscopicProcedureAmountBarData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, EndoscopicProcedureAmount, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_13}`,
    });
  }

  function getSlide7(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let EquipmentSetup = [
      {
        name: " Equipment Setup Bar  Data",
        labels: EQUIPMENT_SETUP_LIST.map((item, index) => item.value),
        values: getEquipmentSetupBarData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, EquipmentSetup, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_14}`,
    });
  }

  function getSlide8(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let sotfwareCostPerYearChart = [
      {
        name: "Equipment Setup Bar  Data",
        labels: ["Average value of software cost in Euro (€)"],
        values: [getSoftwareCostPerYearData()],
      },
    ];

    slide.addChart(pptx.ChartType.bar, sotfwareCostPerYearChart, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\€",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_16}`,
    });
  }

  function getSlide9(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let EndoscopyEquipmentAndServiceCostsChart = [
      {
        name: " Endoscopy Equipment And Service  ",
        labels: ENDOSCOPIC_EQUIPMENT_AND_SERVICE_COST_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: EndoscopyEquipmentAndServiceCostsData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, EndoscopyEquipmentAndServiceCostsChart, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\€",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_17}`,
    });
  }
  function getSlide10(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let uncoverInefficiencyBarChart = [
      {
        name: "Uncover Inefficiency Bar",
        labels: UNCOVER_INEFFICIENCY_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getUncoverInefficiencyBarData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, uncoverInefficiencyBarChart, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_19}`,
    });
  }

  function getSlide11(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let perceivedBenefitsBarChart = [
      {
        name: " Perceived Benefits  ",
        labels: PERCEIVED_BENEFITS_Q24_1_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getPerceivedBenefitsBarDataQ24_1(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, perceivedBenefitsBarChart, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_24_1}`,
    });
  }

  function getSlide12(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let perceivedBenefitsBarChart = [
      {
        name: "Perceived Benefits",
        labels: PERCEIVED_BENEFITS_Q24_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getPerceivedBenefitsBarDataQ24(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, perceivedBenefitsBarChart, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataTableFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_24}`,
    });
  }

  function getSlide13(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let likelihoodOfAdoptionBarChart = [
      {
        name: " Likelihood Of Adoption",
        labels: LIKELIHOOD_OF_ADOPTION_Q25_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfAdoptionBarData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfAdoptionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_25}`,
    });

    let likelihoodOfRecommendationBarchart = [
      {
        name: " Likelihood Of Recommendation",
        labels: LIKELIHOOD_OF_RECOMMENDATION_Q26_LIST.map((item, index) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfRecommendationBarData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfRecommendationBarchart, {
      x: 7.0,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_26}`,
    });
  }

  function getSlide14(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let willingnessToPayHistogram = [
      {
        name: "Willingness To Pay Histogram",
        labels: [0, 20, 40, 60, 80, 100],
        values: getWillingnessToPayHistogramData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, willingnessToPayHistogram, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_27}`,
    });
  }

  function getSlide15(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let serviceContractBarchart = [
      {
        name: "Service Contract Data",
        labels: SERVICE_CONTRACT_OPS_OPTI_LIST.map((item) => item.value),
        values: getServiceContractBarData(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, serviceContractBarchart, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_28}`,
    });
  }

  function getSlide16(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let PprceivedBenefitsBarDataQ30_1 = [
      {
        name: " Service Contract Data",
        labels: PERCEIVED_BENEFITS_Q30_1_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getPerceivedBenefitsBarDataQ30_1(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, PprceivedBenefitsBarDataQ30_1, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_30}`,
    });
  }

  function getSlide17(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let likelihoodOfAdoptionBarChart = [
      {
        name: "Likelihood Of Adoption",
        labels: LIKELIHOOD_OF_ADOPTION_Q31_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getlikelihoodOfAdoptionBarDataQ31(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfAdoptionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_31}`,
    });

    let likelihoodOfRecommendationBarData = [
      {
        name: " Likelihood Of Recommendation",
        labels: LIKELIHOOD_OF_RECOMMENDATION_Q32_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfRecommendationBarDataQ32(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfRecommendationBarData, {
      x: 7,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_32}`,
    });
  }

  function getSlide18(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let PerceivedBenefitsBarChartQ36_1 = [
      {
        name: "Service Contract",
        labels: PERCEIVED_BENEFITS_Q36_1_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getPerceivedBenefitsBarDataQ36_1(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, PerceivedBenefitsBarChartQ36_1, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_36}`,
    });
  }

  function getSlide19(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let likelihoodOfAdoptionBarChart = [
      {
        name: " Likelihood Of Adoption",
        labels: LIKELIHOOD_OF_ADOPTION_Q37_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfAdoptionBarDataQ37ForChart(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfAdoptionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_37}`,
    });

    let likelihoodOfRecommendationBarData = [
      {
        name: "Likelihood Of Recommendation",
        labels: LIKELIHOOD_OF_RECOMMENDATION_Q38_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfRecommendationBarDataQ38(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfRecommendationBarData, {
      x: 7,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_38}`,
    });
  }

  function getSlide20(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let PerceivedBenefitsBarChartQ42_1 = [
      {
        name: "Service Contract",
        labels: PERCEIVED_BENEFITS_Q36_1_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getPerceivedBenefitsBarDataQ42_1(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, PerceivedBenefitsBarChartQ42_1, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_42}`,
    });
  }

  function getSlide21(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let likelihoodOfAdoptionBarChart = [
      {
        name: " Likelihood Of Adoption",
        labels: LIKELIHOOD_OF_ADOPTION_Q43_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfAdoptionBarDataQ43(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfAdoptionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_43}`,
    });

    let likelihoodOfRecommendationBarData = [
      {
        name: " Likelihood Of Recommendation",
        labels: LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfRecommendationBarDataQ44(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfRecommendationBarData, {
      x: 7,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_44}`,
    });
  }

  function getSlide22(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let PerceivedBenefitsBarChartQ48_1 = [
      {
        name: "Service Contract",
        labels: PERCEIVED_BENEFITS_Q48_1_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getPerceivedBenefitsBarDatatQ48_1(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, PerceivedBenefitsBarChartQ48_1, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_48}`,
    });
  }
  function getSlide23(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let likelihoodOfAdoptionBarChart = [
      {
        name: " Likelihood Of Adoption",
        labels: LIKELIHOOD_OF_ADOPTION_Q49_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfAdoptionBarDataQ49(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfAdoptionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_49}`,
    });

    let likelihoodOfRecommendationBarData = [
      {
        name: "Likelihood Of Recommendation",
        labels: LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getLikelihoodOfRecommendationBarDataQ50(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfRecommendationBarData, {
      x: 7,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_50}`,
    });
  }

  function getSlide24(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let likelihoodOfAdoptionBarChart = [
      {
        name: "Manufacturer Preference",
        labels: MANUFACTURER_PERFERENCE_Q54_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getManufacturerPreferenceBarDataQ54(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfAdoptionBarChart, {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_54}`,
    });

    let likelihoodOfRecommendationBarData = [
      {
        name: "Manufacturer Preference",
        labels: SWITCHING_MANUFACTURER_Q55_LIST.map((item) =>
          item.value.join(" ")
        ),
        values: getManufacturerPreferenceBarDataQ55(),
      },
    ];

    slide.addChart(pptx.ChartType.bar, likelihoodOfRecommendationBarData, {
      x: 7,
      y: 0.6,
      w: 6,
      h: "80%",
      showTitle: true,
      chartColors: [colorPaletteState[0]],
      showValue: true,
      chartArea: { fill: { color: "F1F1F1" } },
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelFormatCode: "0\\%",
      titleFontFace: "Helvetica Neue",
      titleFontSize: 11,
      dataLabelFontSize: 7,
      legendFontSize: 10,
      catAxisLabelFontSize: 8,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_55}`,
    });
  }

  function handleLogout() {
    Auth.signOut().then(() => console.log("User signed out successfully"));
  }

  function handleClick() {
    let pptx = new pptxgen();

    pptx.layout = "LAYOUT_WIDE";

    pptx.defineSlideMaster({
      title: "MASTER_SLIDE",
      background: { fill: "F1F1F1" },
      margin: [0.5, 0.25, 1.0, 0.25],
      objects: [
        {
          // rect: {
          //   x: 0.0,
          //   y: 6.9,
          //   w: "100%",
          //   h: 0.6,
          //   //fill: { color: "003b75" },
          // },
          rect: {
            x: 0.0,
            y: 6.9,
            w: "100%",
            h: 0.6,
            //fill: { color: "white" },
          },
        },
        {
          image: {
            x: 12.8,
            y: 7.0,
            w: 0.6,
            h: 0.5,
            data: STARLABS_LOGO_SM,
          },
        },
        {
          text: {
            options: {
              x: 0.63,
              y: 7.26,
              w: "100%",
              //h: 0.6,
              //align: "center",
              //valign: "middle",
              color: "#AEB4BA",
              fontSize: 8,
            },
            text: "CONFIDENTIAL, @ 2024 Tehri Hills",
          },
        },
      ],
      slideNumber: {
        x: 0.33,
        y: 7.1,
        //h: 0.6,
        color: "000000",
        fontFace: "Arial",
        fontSize: 10,
        //align: "center",
      },
    });

    getSlide1(pptx);
    getSlide2(pptx);
    getSlide3(pptx);
    getSlide4(pptx);
    getSlide5(pptx);
    getSlide6(pptx);
    getSlide7(pptx);
    getSlide8(pptx);
    getSlide9(pptx);
    getSlide10(pptx);
    // getSlide11(pptx);
    getSlide12(pptx);
    getSlide13(pptx);
    // getSlide14(pptx);
    getSlide15(pptx);
    getSlide16(pptx);
    getSlide17(pptx);
    getSlide18(pptx);
    getSlide19(pptx);
    getSlide20(pptx);
    getSlide21(pptx);
    getSlide22(pptx);
    getSlide23(pptx);
    getSlide24(pptx);

    var d = new Date();

    pptx.writeFile({
      fileName:
        "Report_" +
        d.getTime() +
        "/" +
        d.getDate() +
        "/" +
        d.getMonth() +
        "/" +
        d.getFullYear() +
        ".pptx",
    });
  }

  return (
    <>
      <Navbar expand="lg" className="navbar-container justify-content-between">
        <Container fluid>
          <Navbar.Brand href="https://www.tehrihills.com/">
            <img
              src="./tehri-hills.png"
              alt="my logo img"
              height="75"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav>
              <Nav.Link
                style={{ cursor: "pointer" }}
                role="navigation"
                as={Link}
                to="/"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Home
                    </span>
                    <FaHome style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link
                style={{ cursor: "pointer" }}
                role="navigation"
                as={Link}
                to="/summary"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Summary
                    </span>
                    <FaDisplay style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link
                onClick={handleFiltersShow}
                style={{ cursor: "pointer" }}
                role="navigation"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Filters
                    </span>
                    <FaFilter style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link onClick={handleShow} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Color Palette
                    </span>
                    <CgColorPicker style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link
                //to="/"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                <IconContext.Provider value={{ color: "white", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Export
                    </span>
                    <FiDownload style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link onClick={handleLogout} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Logout
                    </span>
                    <FiLogOut />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ColorPickerModal
        show={show}
        onClose={handleClose}
        onSaveChanges={handleSaveChanges}
        colorList={colorList}
        onColorChange={handleColorChange}
        onColorRemove={handleColorRemove}
        onColorAdd={handleColorAdd}
        onFocusChange={handleFocus}
        onBlurChange={handleBlur}
        onRevertChanges={handleRevert}
      />
      <FilterModal show={showFilters} onClose={handleCloseFilterModal} />
      <ToastContainer />
    </>
  );
}
