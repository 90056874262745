import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { Col, Row } from "react-bootstrap";
import { SCRENNER_QUESTIONS } from "../../constants/constants";
import CountryPieChartQ1 from "./CountryPieChartQ1";
import RoleDataPieChartQ3 from "./RoleDataPieChartQ3";
import EquipmentAndSoftwareSelectionBarChartQ4 from "./EquipmentAndSoftwareSelectionBarChartQ4";
import PurchaseOrderBarChartQ5 from "./PurchaseOrderBarChartQ5";
import InfluencingCriteriaBarChartQ6_1 from "./InfluencingCriteriaBarChartQ6_1";
import InfluencingCriteriaBarChartQ6_2 from "./InfluencingCriteriaBarChartQ6_2";
import InfluencingCriteriaBarChartQ6_3 from "./InfluencingCriteriaBarChartQ6_3";
import FacilityTypeBarChartQ7 from "./FacilityTypeBarChartQ7";
import FacilitySizeBarChartQ8 from "./FacilitySizeBarChartQ8";
import FacilityLocationBarChartQ9 from "./FacilityLocationBarChartQ9";
import ServiceContractBarChartQ12 from "./ServiceContractBarChartQ12";

function Screener() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        SCRENNER_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          {(filteredQuestionListContext.state.allQuestions ||
            ["Q1", "Q3"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              {filteredQuestionListContext.state.allQuestions && (
                <>
                  <Col sm={12} md={6}>
                    <CountryPieChartQ1
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <RoleDataPieChartQ3
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                    />
                  </Col>
                </>
              )}
              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q1"
              ) && (
                <Col>
                  <CountryPieChartQ1
                    countryFilterValue={filterContext.state.countryFilterValue}
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q3"
              ) && (
                <Col>
                  <RoleDataPieChartQ3
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                  />
                </Col>
              )}
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q4", "Q5"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              {filteredQuestionListContext.state.allQuestions && (
                <>
                  <Col sm={12} md={6}>
                    <EquipmentAndSoftwareSelectionBarChartQ4
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <PurchaseOrderBarChartQ5
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                    />
                  </Col>
                </>
              )}
              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q4"
              ) && (
                <Col>
                  <EquipmentAndSoftwareSelectionBarChartQ4
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q5"
              ) && (
                <Col>
                  <PurchaseOrderBarChartQ5
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                  />
                </Col>
              )}
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q6_1", "Q6_2", "Q6_3"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              {filteredQuestionListContext.state.allQuestions && (
                <>
                  <Col sm={12} md={4}>
                    <InfluencingCriteriaBarChartQ6_1
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <InfluencingCriteriaBarChartQ6_2
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <InfluencingCriteriaBarChartQ6_3
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                    />
                  </Col>
                </>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q6_1"
              ) && (
                <Col>
                  <InfluencingCriteriaBarChartQ6_1
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q6_2"
              ) && (
                <Col>
                  <InfluencingCriteriaBarChartQ6_2
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q6_3"
              ) && (
                <Col>
                  <InfluencingCriteriaBarChartQ6_3
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                  />
                </Col>
              )}
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q7", "Q8", "Q9"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              {filteredQuestionListContext.state.allQuestions && (
                <>
                  <Col sm={12} md={4}>
                    <FacilityTypeBarChartQ7
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                      facilityTypeFilterValue={
                        filterContext.state.facilityTypeFilterValue
                      }
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <FacilitySizeBarChartQ8
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                      facilityTypeFilterValue={
                        filterContext.state.facilityTypeFilterValue
                      }
                      facilitySizeFilterValue={
                        filterContext.state.facilitySizeFilterValue
                      }
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <FacilityLocationBarChartQ9
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                      roleFilterValue={filterContext.state.roleFilterValue}
                      facilityTypeFilterValue={
                        filterContext.state.facilityTypeFilterValue
                      }
                      facilitySizeFilterValue={
                        filterContext.state.facilitySizeFilterValue
                      }
                      facilityLocationFilterValue={
                        filterContext.state.facilityLocationFilterValue
                      }
                    />
                  </Col>
                </>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q7"
              ) && (
                <Col>
                  <FacilityTypeBarChartQ7
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q8"
              ) && (
                <Col>
                  <FacilitySizeBarChartQ8
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q9"
              ) && (
                <Col>
                  <FacilityLocationBarChartQ9
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              )}
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q12"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              {(filteredQuestionListContext.state.allQuestions ||
                filteredQuestionListContext.state.filteredQuestions.includes(
                  "Q12"
                )) && (
                <Col>
                  <ServiceContractBarChartQ12
                    countryFilterValue={filterContext.state.countryFilterValue}
                    roleFilterValue={filterContext.state.roleFilterValue}
                    facilityTypeFilterValue={
                      filterContext.state.facilityTypeFilterValue
                    }
                    facilitySizeFilterValue={
                      filterContext.state.facilitySizeFilterValue
                    }
                    facilityLocationFilterValue={
                      filterContext.state.facilityLocationFilterValue
                    }
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      )}
    </div>
  );
}

export default Screener;
