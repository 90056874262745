const COLOR_RED = "#EE4B2B";
const COLOR_BLUE = "#36A2EB";
const COLOR_YLW = "#F2AF00";
const COLOR_GRN = "#7AB800";
const COLOR_COMP = "#4472C4";
const COLOR_CANC = "#672C7E";
const COLOR_UNK = "#A9A9A9";

export const SURVEY_ID = "183e/231024";
export const PAGE_SIZE = 1000;

export const TITLE_FONT_SIZE = 12;
export const TITLE_FONT_FACE = "Avenir Next LT Pro (Body)";
export const LEGEND_FONT_FACE = "Avenir Next LT Pro (Body)";
export const DATA_LABEL_FONT_FACE = "Avenir Next LT Pro (Body)";

export const DATA_LABEL_FONT_SIZE = 8;

export const COLORS_RYGU = [
  COLOR_RED,
  COLOR_BLUE,
  COLOR_YLW,
  COLOR_GRN,
  COLOR_COMP,
  COLOR_CANC,
  COLOR_UNK,
];

// Question List
export const QUESTION_1 = "Q1- Which country do you currently work in?";
export const QUESTION_3 =
  "Q3- Which of the following best describes your role at your primary facility?";

export const QUESTION_4 =
  "Q4- Which of the following best describes your role in evaluating purchases of GI endoscopy (both hardware like endoscopes and washers disinfectors, as well as software, e.g. workflow management software)?";

export const QUESTION_5 =
  "Q5- Which of the following best describes your role in negotiating, soliciting bids and creating purchase orders for GI endoscopy equipment and respective service contracts?";

export const QUESTION_6_1 =
  "Q6.1- Which of the following best describes your role in the cleaning and disinfection of endoscopy equipment?";

export const QUESTION_6_2 =
  "Q6.2- Which of the following best describes your role in the maintenance and servicing of endoscopy equipment?";

export const QUESTION_6_3 =
  "Q6.3- How familiar are you with pricings for service offers - service contract and on-demand services?";

export const QUESTION_7 = "Q7- What type of facility do you primarily work at?";
export const QUESTION_8 = "Q8- What is the size of the facility you work at?";
export const QUESTION_9 = "Q9- What area is your facility located in?";
export const QUESTION_12 =
  "Q12- Now, we would like to understand how endoscopic equipment at your primary facility is serviced and maintained.";

export const QUESTION_13 =
  "Q13- How many diagnostic and therapeutic GI endoscopy procedures are performed at your facility, per week?";

export const QUESTION_14 =
  "Q14- How do you reprocess GI flexible endoscopes at your primary facility?";

export const QUESTION_16 =
  "Q16- How much does this software cost per year (for your full facility)?";

export const QUESTION_17 =
  "Q17- How much does your primary facility roughly spend on the following aspects, per year?";

export const QUESTION_19 =
  "Q19- How many endoscopic procedures on an average are either delayed or need to be rescheduled per month due to problems with the endoscopy equipment?";

export const QUESTION_24_1 =
  "Q24.1- What impact do you anticipate if your primary facility started using OpsOpti solution?";

export const QUESTION_24 =
  "Q24- What impact do you anticipate if your primary facility started using OpsOpti solution? (if rated between 5-7)";

export const QUESTION_25 =
  "Q25- How likely is it that your facility would adopt OpsOpti solution?";

export const QUESTION_26 =
  "Q26- How likely is it that you would advocate for the purchase of OpsOpti solution at your primary facility?";

export const QUESTION_27 =
  "Q27- Imagine that your annual costs for service and repairs were at 100K € – how much would you be willing to pay in addition, if you could use OpsOpti solution, in %?";

export const QUESTION_28 =
  "Q28- How would you want the OpsOpti solution to be offered to you?";

export const QUESTION_30 =
  "Q30- What impact do you anticipate if your primary facility started using AutoMaintenance solution?";

export const QUESTION_31 =
  "Q31- How likely is it that your facility would adopt AutoMaintenance solution?";

export const QUESTION_32 =
  "Q32- How likely is it that you would advocate for the purchase of AutoMaintenance solution at your primary facility?";

export const QUESTION_33 =
  "Q33- Imagine that your annual costs for service and repairs were at 100K € – how much would you be willing to pay in addition, if you could use AutoMaintenance solution, in %?";

export const QUESTION_36 =
  "Q36- What impact do you anticipate if your primary facility started using StaffTraining solution?";

export const QUESTION_37 =
  "Q37- How likely is it that your facility would adopt StaffTraining solution?";

export const QUESTION_38 =
  "Q38- How likely is it that you would advocate for the purchase of StaffTraining solution at your primary facility?";

export const QUESTION_39 =
  "Q39- Imagine that your annual costs for service and repairs were at 100K € – how much would you be willing to pay in addition, if you could use StaffTraining solution, in %?";

export const QUESTION_42 =
  "Q42- What impact do you anticipate if your primary facility started using SupportBot solution?";

export const QUESTION_43 =
  "Q43- How likely is it that your facility would adopt SupportBot solution?";

export const QUESTION_44 =
  "Q44- How likely is it that you would advocate for the purchase of SupportBot solution at your primary facility?";

export const QUESTION_45 =
  "Q45- Imagine that your annual costs for service and repairs were at 100K € – how much would you be willing to pay in addition, if you could use SupportBot solution, in %?";

export const QUESTION_48 =
  "Q48- What impact do you anticipate if your primary facility started using SupportBot solution?";

export const QUESTION_49 =
  "Q49- How likely is it that your facility would adopt OpsReport solution?";

export const QUESTION_50 =
  "Q50- How likely is it that you would advocate for the purchase of OpsReport solution at your primary facility?";

export const QUESTION_51 =
  "Q51- Imagine that your annual costs for service and repairs were at 100K € – how much would you be willing to pay in addition, if you could use OpsReport solution, in %?";

export const QUESTION_54 =
  "Q54- How likely is it that you would buy equipment from a manufacturer offering these types of solutions, compared to one that does not?";

export const QUESTION_55 =
  "Q55- How likely is it that you would switch your equipment fleet to another manufacturer, if they offered solutions like the ones discussed?";

export const COUNTRY_LIST = [
  { key: "1", value: "Germany" },
  { key: "2", value: "UK" },
  { key: "3", value: "Spain" },
  { key: "4", value: "U.S." },
  { key: "5", value: "Japan" },
];

export const ROLE_LIST = [
  {
    key: "1",
    value: "Chief / Head / Director / Senior Physician of GI Endoscopy",
  },
  { key: "2", value: "Endoscopy Nurse / Nurse Manager" },
  { key: "3", value: "Reprocessing / Sterilisation Technician" },
  { key: "4", value: "Biomedical Technician / Medical Engineer" },
  { key: "5", value: "Hospital Administrator (CEO, President, Director)" },
  {
    key: "6",
    value: "Procurement Manager / Supplier Processing and Distribution",
  },
];

export const EQUIPMENT_AND_SOFTWARE_SELECTION_LIST = [
  {
    key: "1",
    value: [
      "I'm",
      "solely",
      "responsible",
      "for",
      "evaluating",
      "potential",
      "solutions",
      "for",
      "GI endoscopy",
    ],
  },
  {
    key: "2",
    value: [
      "I'm",
      "a key",
      "decision-maker",
      "and",
      "play",
      "a",
      "significant",
      "role",
      "in",
      "the",
      "evaluation",
    ],
  },
  {
    key: "3",
    value: [
      "I'm",
      "moderately",
      "involved",
      "and",
      "contribute",
      "actively",
      "to",
      "equipment",
      "evaluation",
    ],
  },
  {
    key: "4",
    value: [
      "I provide",
      "occasional",
      "input",
      "during",
      "the",
      "evaluation",
      "process",
    ],
  },
  {
    key: "5",
    value: [
      "I'm",
      "not",
      "at all",
      "involved",
      "in",
      "the",
      "decision-making",
      "process",
    ],
  },
];

export const PURCHASE_ORDER_LIST = [
  {
    key: "1",
    value: [
      "I'm",
      "one of",
      "the primary",
      "decision makers",
      "in the",
      "purchase",
      "decision-making",
      "process",
      "and",
      "I'm",
      "part of",
      "a committee",
      "reviewing",
      "medical",
      "and",
      "surgical",
      "product",
    ],
  },
  {
    key: "2",
    value: [
      "I'm",
      "a key",
      "decision-maker",
      "and play",
      "a significant",
      "role in",
      "the evaluation,",
      "my",
      "evaluation",
      "influences",
      "the decision",
      "for or",
      "against",
      "new",
      "purchase",
    ],
  },
  {
    key: "3",
    value: [
      "I'm",
      "moderately",
      "involved and",
      "contribute",
      "actively",
      "to equipment",
      "evaluation,",
      "my evaluation",
      "partially",
      "influences",
      "the decision",
      "for or",
      "against",
      "new",
      "purchases",
    ],
  },
  {
    key: "4",
    value: [
      "I provide",
      "occasional",
      "input",
      "during",
      "the evaluation",
      "process,",
      "my evaluation",
      "slightly",
      "influences",
      "the decision",
      "for or",
      "against",
      "new purchases",
    ],
  },
  {
    key: "5",
    value: [
      "I'm",
      "not",
      "at all",
      "involved",
      "in the",
      "decision-making",
      "process",
    ],
  },
];

export const INFLUENCING_CRITERIA_LIST_1 = [
  {
    key: "1",
    value: [
      "I'm",
      "solely",
      "responsible",
      "for",
      "equipment",
      "cleaning",
      "and",
      "disinfection",
    ],
  },
  {
    key: "2",
    value: [
      "I'm",
      "highly",
      "involved",
      "and",
      "take a",
      "lead role",
      "in equipment",
      "cleaning",
      "and",
      "disinfection",
    ],
  },
  {
    key: "3",
    value: [
      "I'm",
      "moderately",
      "involved",
      "and",
      "participat",
      "in",
      "equipment",
      "cleaning",
      "and",
      "disinfection",
    ],
  },
  {
    key: "4",
    value: [
      "I have",
      "limited",
      "involvement",
      "and",
      "occasionally",
      "assist",
    ],
  },
  {
    key: "5",
    value: ["I am", "not", "involved", "in any", "of", "these", "activities"],
  },
];

export const INFLUENCING_CRITERIA_LIST_2 = [
  {
    key: "1",
    value: [
      "I'm",
      "solely",
      "responsible",
      "for",
      "equipment",
      "maintenance",
      "and",
      "servicing",
    ],
  },
  {
    key: "2",
    value: [
      "I'm",
      "highly",
      "involved",
      "and",
      "take a",
      "lead role",
      "in",
      "equipment",
      "maintenance",
      "and",
      "servicing",
    ],
  },
  {
    key: "3",
    value: [
      "I'm",
      "moderately",
      "involved",
      "and",
      "participate",
      "in",
      "equipment",
      "maintenance",
      "and",
      "servicing",
    ],
  },
  {
    key: "4",
    value: [
      "I have",
      "limited",
      "involvement",
      "and",
      "occasionally",
      "assist",
    ],
  },
  {
    key: "5",
    value: ["I'm", "not", "involved", "in any", "of", "these", "activities"],
  },
];

export const INFLUENCING_CRITERIA_LIST_3 = [
  {
    key: "1",
    value: ["Very familiar-", "I know", "prices", "exactly"],
  },
  {
    key: "2",
    value: ["Familiar-", "I have", "good", "appreciation", "of", "prices"],
  },
  {
    key: "3",
    value: [
      "Somewhat",
      "familiar-",
      "I know",
      "the price",
      "ranges",
      "approximately",
    ],
  },
  {
    key: "4",
    value: [
      "Not at",
      "all familiar-",
      "I have",
      "very",
      "little",
      "or no",
      "idea",
      "of service",
      "contract",
      "prices",
    ],
  },
];

export const FACILITY_TYPE_LIST = [
  {
    key: "1",
    value: ["Academic", "university", "hospital"],
  },
  {
    key: "2",
    value: [
      "General",
      "community",
      "hospital",
      "with",
      "a",
      "teaching",
      "programm",
    ],
  },
  {
    key: "3",
    value: ["General", "community", "without", "a", "teaching", "programm"],
  },
  {
    key: "4",
    value: [
      "Outpatient",
      "clinic",
      "ambulatory",
      "care",
      "clinic",
      "Ambulatory",
      "Endoscopy",
      "Center",
    ],
  },
  {
    key: "5",
    value: ["Other"],
  },
];

export const FACILITY_SIZE_LIST = [
  {
    key: "1",
    value: ["Less", " than", " 100", " beds"],
  },
  {
    key: "2",
    value: ["100 - 200", "beds"],
  },
  {
    key: "3",
    value: ["201 - 400", "beds"],
  },
  {
    key: "4",
    value: ["400 - 600", "beds"],
  },
  {
    key: "5",
    value: ["More", "than", "600 ", "beds"],
  },
  {
    key: "6",
    value: ["Outpatient", "clinic", "with", "no", " beds"],
  },
];

export const FACILITY_LOCATION_LIST = [
  {
    key: "1",
    value: ["In a", "rural", "area"],
  },
  {
    key: "2",
    value: ["In a", "suburban", "area"],
  },
  {
    key: "3",
    value: ["In an", "urban", " area / city"],
  },
];

export const SERVICE_CONTRACT_LIST = [
  {
    key: "Q12r1",
    value: [
      "A service",
      "contract ",
      "with the",
      "original",
      "manufacturer: [ % ]",
    ],
  },
  {
    key: "Q12r2",
    value: [
      "A service",
      "contract ",
      "with a ",
      "third - party",
      "service ",
      "provider: [ % ]",
    ],
  },
  {
    key: "Q12r3",
    value: [
      "A service",
      "contract",
      "with a ",
      "global",
      "service",
      "provider: [ % ]",
    ],
  },
  {
    key: "Q12r4",
    value: [
      "An on-demand",
      "basis ",
      "via the",
      "original",
      "manufacturer: [ % ]",
    ],
  },
  {
    key: "Q12r5",
    value: [
      "An on-demand",
      "basis",
      "via a",
      "third - party",
      "service",
      "provider: [ % ]",
    ],
  },
  // {
  //   key: "noanswerQ12_r6",
  //   value: ["I am", "not aware ", "of service ", "contract", "coverage"],
  // },
];

export const ENDOSCOPIC_PROCEDURE_AMOUNT_LIST = [
  { key: "Q13r1c1", value: "Diagnostic GI endoscopic procedures:" },
  { key: "Q13r2c1", value: "Therapeutic GI endoscopic procedures:" },
];

export const EQUIPMENT_SETUP_LIST = [
  { key: "Q14_3r1", value: "At the endoscopy department itself" },
  {
    key: "Q14_3r2",
    value: "At the reprocessing department within the facility",
  },
  { key: "Q14_3r3", value: "Via a third party" },
];

export const ENDOSCOPIC_EQUIPMENT_AND_SERVICE_COST_LIST = [
  {
    key: "Q17r1c1",
    key1: "Q17x2r1c2",
    value: [
      "New equipment",
      "incl.",
      "flexible endoscopes",
      " and periphery",
      " (processors, monitors, pumps, etc.)",
    ],
  },
  {
    key: "Q17r2c1",
    key1: "Q17x2r2c2",
    value: ["Service costs", " (on demand or service contract)"],
  },
  {
    key: "Q17r3c1",
    key1: "Q17x2r3c2",
    value: [
      "New equipment",
      " (machines, drying cabinets, without chemical costs)",
    ],
  },
  {
    key: "Q17r4c1",
    key1: "Q17x2r4c2",
    value: ["Service costs", " (on demand or service contract)"],
  },
];

export const UNCOVER_INEFFICIENCY_LIST = [
  {
    key: "Q19r1c1",
    key1: "Q19x2r1c2",
    value: ["No. of", "procedure", "delayed", "per month:"],
  },
  {
    key: "Q19r2c1",
    key1: "Q19x2r2c2",
    value: ["Average", "delay per", "procedure: (in minutes)"],
  },
  {
    key: "Q19b",
    value: ["No. of", "procedures ", "completely", "rescheduled", "per month:"],
  },
];

export const PERCEIVED_BENEFITS_Q24_1_LIST = [
  {
    key: "Q24r1",
    key1: "Q24_numr1",
    value: [
      "Question1:",
      "Time",
      "saving",
      "for",
      "operational",
      "staff",
      "(Reprocessing,",
      "Biomed,",
      "Hospital",
      "admin,",
      "Procurement)",
    ],
  },
  {
    key: "Q24r2",
    key1: "Q24_numr2",
    value: [
      "Question 2:",
      "Time",
      "savings",
      "for",
      "clinical",
      "staff",
      "(Physician,",
      "endoscopy nurse)",
    ],
  },
  {
    key: "Q24r3",
    key1: "Q24_numr3",
    value: [
      "Question 3:",
      "Reduced",
      "administrative",
      "work",
      "for",
      "clinical",
      "staff",
    ],
  },
  {
    key: "Q24r4",
    key1: "Q24_numr4",
    value: [
      "Question 4:",
      "Streamlined",
      "procedures",
      "(less",
      "time",
      "needed",
      "per",
      "procedure)",
    ],
  },
  {
    key: "Q24r5",
    key1: "Q24_numr5",
    value: [
      "Question 5:",
      "Reduced",
      "schedule",
      "interruptions",
      "/delays",
      "in",
      "procedures",
    ],
  },
  {
    key: "Q24r6",
    key1: "Q24_numr6",
    value: ["Question 6:", "Reduced", "repair", "and", "maintenance", "cases"],
  },
  {
    key: "Q24r7",
    key1: "Q24_numr7",
    value: [
      "Question 7:",
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
    ],
  },
  {
    key: "Q24r8",
    key1: "Q24_numr8",
    value: [
      "Question 8:",
      "Increased",
      "equipment",
      "transparency",
      "(status,",
      "condition,",
      "location,",
      "utilization,",
    ],
  },
  {
    key: "Q24r9",
    key1: "Q24_numr9",
    value: ["Question 9:", "Frictionless", "reprocessing", "cycle"],
  },
  {
    key: "Q24r10",
    key1: "Q24_numr10",
    value: ["Question 10:", "Increased satff satisfaction"],
  },
  {
    key: "Q24r11",
    key1: "Q24_numr11",
    value: ["Question 11:", "Improved", "patient", "care"],
  },
  {
    key: "Q24r12",
    key1: "Q24_numr12",
    value: ["Question 12:", "Other (specify)"],
  },
];

export const PERCEIVED_BENEFITS_Q24_LIST = [
  {
    key: "Q24r1",
    key1: "Q24_numr1",
    value: [
      "Average time",
      "savings",
      "for",
      "operational",
      "staff",
      "(Reprocessing, Biomed, Hospital admin, Procurement)",
      "(in mins)",
    ],
  },
  {
    key: "Q24r2",
    key1: "Q24_numr2",
    value: [
      "Average time",
      "savings",
      "for",
      "clinical",
      "staff",
      "(Physician, endoscopy nurse)",
      "(in mins)",
    ],
  },
  {
    key: "Q24r7",
    key1: "Q24_numr7",
    value: [
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
      "(# per week)",
    ],
  },
];

export const LIKELIHOOD_OF_ADOPTION_Q25_LIST = [
  {
    key: "Q25",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q25",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q25",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q25",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q25",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q25",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q25",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const LIKELIHOOD_OF_RECOMMENDATION_Q26_LIST = [
  {
    key: "Q26",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q26",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q26",
    realValue: "5",
    value: ["Somewhat", " likely"],
  },
  {
    key: "Q26",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q26",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q26",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q26",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const SERVICE_CONTRACT_OPS_OPTI_LIST = [
  { key: "Q28r1", value: "As a software offering" },
  {
    key: "Q28r2",
    value: "As an inclusive service offering",
  },
  { key: "Q28r3", value: "A flexible offering based on your needs" },
  { key: "Q28r4", value: "Other (specify)" },
];

export const PERCEIVED_BENEFITS_Q30_LIST = [
  {
    key: "Q30r1",
    key1: "Q30_numr1",
    value: [
      "Question1:",
      "Time savings",
      "for operational",
      "staff",
      "(Reprocessing,",
      "Biomed,",
      "Hospital admin,",
      " Procurement)",
    ],
  },
  {
    key: "Q30r2",
    key1: "Q30_numr2",
    value: [
      "Question 2:",
      "Time savings",
      "for clinical",
      "staff",
      "(Physician,",
      "endoscopy nurse)",
    ],
  },
  {
    key: "Q30r3",
    key1: "Q30_numr3",
    value: [
      "Question 3:",
      "Reduced",
      "schedule",
      "interruptions",
      "/ delays",
      "in",
      "procedures",
    ],
  },
  {
    key: "Q30r4",
    key1: "Q30_numr4",
    value: [
      "Question 4:",
      "Reduced",
      "large-scale",
      "repair",
      "and",
      "maintenance",
      "costs",
    ],
  },
  {
    key: "Q30r5",
    key1: "Q30_numr5",
    value: [
      "Question 5:",
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
    ],
  },
  {
    key: "Q30r6",
    key1: "Q30_numr6",
    value: [
      "Question 6:",
      "Increased",
      "equipment",
      "transparency",
      "(status,",
      "condition",
      "location",
      "utilization)",
    ],
  },
  {
    key: "Q30r7",
    key1: "Q30_numr7",
    value: ["Question 7:", "Frictionless", "reprocessing", "cycle"],
  },
  {
    key: "Q30r8",
    key1: "Q30_numr8",
    value: ["Question 8:", "Increased", "staff", "satisfaction"],
  },
  {
    key: "Q30r9",
    key1: "Q30_numr9",
    value: ["Question 9:", "Improved", "patient", "care"],
  },
  {
    key: "Q30r10",
    key1: "Q30_numr10",
    value: ["Question 10:", "Other (specify)"],
  },
];

export const PERCEIVED_BENEFITS_Q30_1_LIST = [
  {
    key: "Q30r1",
    key1: "Q30_numr1",
    value: [
      "Average time savings",
      "for operational",
      "staff",
      "(Reprocessing, Biomed, Hospital admin, Procurement)",
      "(in mins)",
    ],
  },
  {
    key: "Q30r2",
    key1: "Q30_numr2",
    value: [
      "Average time savings",
      "for clinical",
      "staff",
      "(Physician, endoscopy nurse)",
      "(in mins)",
    ],
  },
  {
    key: "Q30r5",
    key1: "Q30_numr5",
    value: [
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
      "(# per week)",
    ],
  },
];

export const LIKELIHOOD_OF_ADOPTION_Q31_LIST = [
  {
    key: "Q31",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q31",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q31",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q31",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q31",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q31",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q31",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const LIKELIHOOD_OF_RECOMMENDATION_Q32_LIST = [
  {
    key: "Q32",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q32",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q32",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q32",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q32",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q32",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q32",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const PERCEIVED_BENEFITS_Q36_LIST = [
  {
    key: "Q36r1",
    key1: "Q36_numr1",
    value: [
      "Question1:",
      "Time savings",
      "for operational",
      "staff",
      "(Reprocessing,",
      "Biomed,",
      "Hospital admin,",
      " Procurement)",
    ],
  },
  {
    key: "Q36r2",
    key1: "Q36_numr2",
    value: [
      "Question 2:",
      "Time savings",
      "for clinical",
      "staff",
      "(Physician,",
      "endoscopy nurse)",
    ],
  },
  {
    key: "Q36r3",
    key1: "Q36_numr3",
    value: ["Question 3:", "Upskilled", "staff"],
  },
  {
    key: "Q36r4",
    key1: "Q36_numr4",
    value: [
      "Question 4:",
      "Clear",
      "overview",
      "of staff",
      "training",
      "needs",
    ],
  },
  {
    key: "Q36r5",
    key1: "Q36_numr5",
    value: [
      "Question 5:",
      "Reduced",
      "schedule",
      "interruptions",
      "/delays",
      "in",
      "procedures",
    ],
  },
  {
    key: "Q36r6",
    key1: "Q36_numr6",
    value: ["Question 6:", "Reduced", "repair", "and", "maintenance", "cases"],
  },
  {
    key: "Q36r7",
    key1: "Q36_numr7",
    value: [
      "Question 7:",
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
    ],
  },
  {
    key: "Q36r8",
    key1: "Q36_numr8",
    value: ["Question 8:", "Frictionless", "reprocessing", "cycle"],
  },
  {
    key: "Q36r9",
    key1: "Q36_numr9",
    value: ["Question 9:", "Increased", "staff", "satisfaction"],
  },
  {
    key: "Q36r10",
    key1: "Q36_numr10",
    value: ["Question 10:", "Improved", "patient", "care"],
  },
  {
    key: "Q36r11",
    key1: "Q36_numr11",
    value: ["Question 11:", "Other (specify)"],
  },
];

export const PERCEIVED_BENEFITS_Q36_1_LIST = [
  {
    key: "Q36r1",
    key1: "Q36_numr1",
    value: [
      "Time savings",
      "for operational",
      "staff",
      "(Reprocessing, Biomed, Hospital admin, Procurement)",
      "(in mins)",
    ],
  },
  {
    key: "Q36r2",
    key1: "Q36_numr2",
    value: [
      "Time savings",
      "for clinical",
      "staff",
      "(Physician, endoscopy nurse)",
      "(in mins)",
    ],
  },
  {
    key: "Q36r5",
    key1: "Q36_numr5",
    value: [
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
      "(# per week)",
    ],
  },
];

export const LIKELIHOOD_OF_ADOPTION_Q37_LIST = [
  {
    key: "Q37",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q37",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q37",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q37",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q37",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q37",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q37",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const LIKELIHOOD_OF_RECOMMENDATION_Q38_LIST = [
  {
    key: "Q38",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q38",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q38",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q38",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q38",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q38",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q38",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const PERCEIVED_BENEFITS_Q42_LIST = [
  {
    key: "Q42r1",
    key1: "Q42_numr1",
    value: [
      "Question1:",
      "Time savings",
      "for operational",
      "staff",
      "(Reprocessing,",
      "Biomed,",
      "Hospital admin,",
      " Procurement)",
    ],
  },
  {
    key: "Q42r2",
    key1: "Q42_numr2",
    value: [
      "Question 2:",
      "Time savings",
      "for clinical",
      "staff",
      "(Physician,",
      "endoscopy nurse)",
    ],
  },
  {
    key: "Q42r3",
    key1: "Q42_numr3",
    value: ["Question 3:", "Upskilled", "staff"],
  },
  {
    key: "Q42r4",
    key1: "Q42_numr4",
    value: [
      "Question 4:",
      "Reduced",
      "schedule",
      "interruptions",
      "/delays",
      "in",
      "procedures",
    ],
  },
  {
    key: "Q42r5",
    key1: "Q42_numr5",
    value: ["Question 5:", "Reduced", "repair", "and", "maintenance", "cases"],
  },
  {
    key: "Q42r6",
    key1: "Q42_numr6",
    value: ["Question 6:", "Frictionless", "reprocessing", "cycle"],
  },
  {
    key: "Q42r7",
    key1: "Q42_numr7",
    value: ["Question 7:", "Other (specify)"],
  },
];

export const PERCEIVED_BENEFITS_Q42_1_LIST = [
  {
    key: "Q42r1",
    key1: "Q42_numr1",
    value: [
      "Average time savings",
      "for operational",
      "staff",
      "(Reprocessing, Biomed, Hospital admin, Procurement)",
      "(in mins)",
    ],
  },
  {
    key: "Q42r2",
    key1: "Q42_numr2",
    value: [
      "Average time savings",
      "for clinical",
      "staff",
      "(Physician, endoscopy nurse)",
      "(in mins)",
    ],
  },
];

export const LIKELIHOOD_OF_ADOPTION_Q43_LIST = [
  {
    key: "Q43",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q43",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q43",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q43",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q43",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q43",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q43",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST = [
  {
    key: "Q44",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q44",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q44",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q44",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q44",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q44",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q44",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const PERCEIVED_BENEFITS_Q48_LIST = [
  {
    key: "Q48r1",
    key1: "Q48_numr1",
    value: [
      "Question1:",
      "Time savings",
      "for operational",
      "staff",
      "(Reprocessing,",
      "Biomed,",
      "Hospital admin,",
      " Procurement)",
    ],
  },
  {
    key: "Q48r2",
    key1: "Q48_numr2",
    value: [
      "Question 2:",
      "Time savings",
      "for clinical",
      "staff",
      "(Physician,",
      "endoscopy nurse)",
    ],
  },
  {
    key: "Q48r3",
    key1: "Q48_numr3",
    value: ["Question 3:", "Streamlined", "reporting", "process"],
  },
  {
    key: "Q48r4",
    key1: "Q48_numr4",
    value: [
      "Question 4:",
      "Informed",
      "equipment",
      "& service",
      "decisions",
      "based",
      "on",
      "data",
      "driven",
      "insights",
    ],
  },
  {
    key: "Q48r5",
    key1: "Q48_numr5",
    value: [
      "Question 5:",
      "Increased",
      "visibility",
      "into",
      "future",
      "equipment",
      "needs",
      "of",
      "the",
      "department",
    ],
  },
  {
    key: "Q48r6",
    key1: "Q48_numr6",
    value: [
      "Question 6:",
      "Increased",
      "visibility",
      "around",
      "sustainable",
      "practices",
    ],
  },
  {
    key: "Q48r7",
    key1: "Q48_numr7",
    value: ["Question 7:", "Increased", "staff", "satisfaction"],
  },
  {
    key: "Q48r8",
    key1: "Q48_numr8",
    value: ["Question 8:", "Improved", "patient", "care"],
  },
  {
    key: "Q48r9",
    key1: "Q48_numr9",
    value: ["Question 9:", "Other (specify)"],
  },
];

export const PERCEIVED_BENEFITS_Q48_1_LIST = [
  {
    key: "Q48r1",
    key1: "Q48_numr1",
    value: [
      "Average time savings",
      "for operational",
      "staff",
      "(Reprocessing, Biomed, Hospital admin, Procurement)",
      "(in mins)",
    ],
  },
  {
    key: "Q48r2",
    key1: "Q48_numr2",
    value: [
      "Average time savings",
      "for clinical",
      "staff",
      "(Physician, endoscopy nurse)",
      "(in mins)",
    ],
  },
];

export const LIKELIHOOD_OF_ADOPTION_Q49_LIST = [
  {
    key: "Q49",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q49",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q49",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q49",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q49",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q49",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q49",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const LIKELIHOOD_OF_RECOMMENDATION_Q50_LIST = [
  {
    key: "Q50",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q50",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q50",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q50",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q50",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q50",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q50",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const MANUFACTURER_PERFERENCE_Q54_LIST = [
  {
    key: "Q54",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q54",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q54",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q54",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q54",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q54",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q54",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const SWITCHING_MANUFACTURER_Q55_LIST = [
  {
    key: "Q55",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q55",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q55",
    realValue: "5",
    value: ["Somewhat", "likely"],
  },
  {
    key: "Q55",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q55",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q55",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q55",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const SCRENNER_QUESTIONS = [
  "Q1",
  "Q3",
  "Q4",
  "Q5",
  "Q6_1",
  "Q6_2",
  "Q6_3",
  "Q7",
  "Q8",
  "Q9",
  "Q12",
];

export const ENDOSCOPY_EQUIPMENT_QUESTIONS = ["Q13", "Q14", "Q16", "Q17"];
export const KEY_CHALLENGES_QUESTIONS = ["Q19"];
export const OPSOPTI_QUESTIONS = ["Q24", "Q24.1", "Q25", "Q26", "Q27", "Q28"];
export const AUTO_MAINTENANCE_QUESTIONS = ["Q30", "Q30.1", "Q31", "Q32", "Q33"];
export const STAFF_TRAINING_QUESTIONS = ["Q36", "Q37", "Q38", "Q39"];
export const SUPPORTBOT_QUESTIONS = ["Q42", "Q43", "Q44", "Q45"];
export const OPS_REPORT_QUESTIONS = ["Q48", "Q49", "Q50", "Q51"];
export const PRICING_PREFERENCE_QUESTION = ["Q54", "Q55"];
