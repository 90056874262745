import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../LoadingSpinner.js";
import {
  COLORS_RYGU,
  QUESTION_30,
  PERCEIVED_BENEFITS_Q30_LIST,
} from "../../constants/constants.js";
import { Card } from "react-bootstrap";
import { useAPI } from "../../ApiContext.js";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function PerceivedBenefitsBarChartQ30(props) {
  const { dashboardData } = useAPI();
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  const labels = [
    { key: 1, value: "1-I don't anticipate this impact" },
    { key: 2, value: "2" },
    { key: 3, value: "3" },
    { key: 4, value: "4" },
    { key: 5, value: "5" },
    { key: 6, value: "6" },
    { key: 7, value: "7-I anticipate this impact" },
  ];

  function getAveragePercentageForEachOption(resultArray = []) {
    const finalArray = [];

    PERCEIVED_BENEFITS_Q30_LIST.map((item, index) => {
      var test = resultArray
        .map((obj, index1) => obj[item.key])
        .filter((item) => item)
        .reduce((acc, current) => {
          if (current in acc) {
            acc[current]++;
          } else {
            acc[current] = 1;
          }
          return acc;
        }, {});
      //finalArray.push(Object.values(test));
      finalArray.push(
        Object.values(test)
          .filter((obj) => obj)
          .map((item, index) =>
            (
              (item /
                Object.values(test).reduce(
                  (acc, current) => acc + current,
                  0
                )) *
              100
            ).toFixed(1)
          )
      );
    });

    return finalArray;
  }

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 40,
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
          },
        },
        legend: {
          display: true,
          position: "bottom",
          onClick: null, // Disable legend item click event
        },
        datalabels: {
          rotation: -90,
          formatter: (value) => {
            if (value) {
              return value + " %";
            }
          },
          anchor: "end",
          align: "top",
          offset: "0",
          color: "grey",
          font: {
            weight: "bold",
            size: 10,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 6,
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          display: false,
        },
      },
    };
  }

  function getChartData() {
    return {
      labels: PERCEIVED_BENEFITS_Q30_LIST.map((item, index) => item.value),
      datasets: labels.map((label, index) => ({
        label: label.value,
        data: getAveragePercentageForEachOption(dashboardData.data).map(
          (questionResponses) => questionResponses[index]
        ),
        backgroundColor: colorPaletteState[index],
      })),
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_30}</Card.Header>
      <Card.Body>
        {dashboardData.data && dashboardData.data.length > 0 ? (
          <div style={{ width: "100%", height: "90vh" }}>
            <Bar options={getOptions()} data={getChartData()} />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}

export default PerceivedBenefitsBarChartQ30;
