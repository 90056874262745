import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../LoadingSpinner.js";
import { QUESTION_39 } from "../../constants/constants.js";
import { Card } from "react-bootstrap";
import { useAPI } from "../../ApiContext.js";
import { ColorPaletteContext } from "../../App.js";
import { useContext } from "react";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function WillingnessToPayHistogramChartQ39(props) {
  const { dashboardData } = useAPI();
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function histogram(X, binRange) {
    //inclusive of the first number
    var max = Math.max(...X);
    var min = Math.min(...X);
    var len = max - min + 1;
    var numberOfBins = Math.ceil(len / binRange);
    var bins = new Array(numberOfBins).fill(0);
    //-min to normalise values for the array
    X.forEach((x) => bins[Math.floor(x / binRange)]++);
    return bins;
  }

  function getAveragePercentageForEachOption(resultArray = []) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q1 === props.countryFilterValue
      );
    }

    if (props.roleFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q3 === props.roleFilterValue
      );
    }

    if (props.facilityTypeFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q7 === props.facilityTypeFilterValue
      );
    }

    if (props.facilitySizeFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q8 === props.facilitySizeFilterValue
      );
    }

    if (props.facilityLocationFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q9 === props.facilityLocationFilterValue
      );
    }

    resultArray = resultArray.filter((item) => item.Q39);
    const x_values = [10, 30, 50, 70, 90, 110];

    var tempArray = resultArray.filter((obj) => obj["noanswerQ39_r1"] === "0");

    const y_vals = tempArray.map((item, index) => Number(item.Q39));

    if (!y_vals.length) {
      return;
    }

    var test = histogram(y_vals, 20);
    var data = x_values.map((k, i) => ({ x: k, y: test[i] }));
    return data;
  }

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 10,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (items) => {
              if (!items.length) {
                return "";
              }
              const item = items[0];
              const x = item.parsed.x;
              const min = x - 10;
              const max = x + 10;
              return `Advance: ${min} - ${max}`;
            },
          },
        },
      },
      scales: {
        x: {
          type: "linear",
          offset: false,
          grid: {
            offset: false,
          },
          ticks: {
            stepSize: 20,
          },
          title: {
            display: true,
            text: "Addition payment",
            font: {
              size: 14,
            },
          },
        },
        y: {
          // beginAtZero: true
          title: {
            display: true,
            text: "Frequency",
            font: {
              size: 14,
            },
          },
        },
      },
    };
  }

  function getChartData() {
    return {
      labels: [0, 20, 40, 60, 80, 100],
      datasets: [
        {
          label: "Willing to pay in addition",
          data: getAveragePercentageForEachOption(dashboardData.data),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          barPercentage: 1,
          categoryPercentage: 1,
          borderRadius: 5,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_39}</Card.Header>
      <Card.Body>
        {dashboardData.data && dashboardData.data.length > 0 ? (
          <div style={{ width: "100%", height: "60vh" }}>
            <Bar options={getOptions()} data={getChartData()} />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}
export default WillingnessToPayHistogramChartQ39;
