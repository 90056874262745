import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../LoadingSpinner.js";
import {
  QUESTION_44,
  LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST,
} from "../../constants/constants.js";
import { Card } from "react-bootstrap";
import { useAPI } from "../../ApiContext.js";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";
import { getLikelihoodOfRecommendationBarChartQ44 } from "../../Helper.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function LikelihoodOfRecommendationBarChartQ44(props) {
  const { dashboardData } = useAPI();
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 30,
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value) => {
            return value + " %";
          },
          anchor: "end",
          align: "top",
          color: "grey",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          display: false,
        },
      },
    };
  }

  function getChartData() {
    return {
      labels: LIKELIHOOD_OF_RECOMMENDATION_Q44_LIST.map(
        (item, index) => item.value
      ),
      datasets: [
        {
          label: "Service Contract",
          data: getLikelihoodOfRecommendationBarChartQ44(dashboardData.data, {
            countryFilterValue: props.countryFilterValue,
            roleFilterValue: props.roleFilterValue,
          }),
          borderColor: colorPaletteState[0],
          backgroundColor: colorPaletteState[0],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }
  return (
    <Card>
      <Card.Header>{QUESTION_44}</Card.Header>
      <Card.Body>
        {dashboardData.data && dashboardData.data.length > 0 ? (
          <div style={{ width: "100%", height: "60vh" }}>
            <Bar options={getOptions()} data={getChartData()} />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}
export default LikelihoodOfRecommendationBarChartQ44;
