import React, { useContext } from "react";
import { KEY_CHALLENGES_QUESTIONS } from "../../constants/constants";
import { Col, Row } from "react-bootstrap";
import UncoverInefficiencyBarChartQ19 from "./UncoverInefficiencyBarChartQ19";
import { FilterContext, FilteredQuestionListContext } from "../../App";

function KeyChallenges() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        KEY_CHALLENGES_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q19"
              )) && (
              <Col>
                <UncoverInefficiencyBarChartQ19
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default KeyChallenges;
