import React, { useContext } from "react";
import EndoscopicProcedureAmountBarChartQ13 from "./EndoscopicProcedureAmountBarChartQ13";
import EquipmentSetupBarChartQ14 from "./EquipmentSetupBarChartQ14";
import SotfwareCostPerYearBarChartQ16 from "./SotfwareCostPerYearBarChartQ16";
import EndoscopyEquipmentAndServiceCostsBarChartQ17 from "./EndoscopyEquipmentAndServiceCostsBarChartQ17";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { ENDOSCOPY_EQUIPMENT_QUESTIONS } from "../../constants/constants";
import { Col, Row } from "react-bootstrap";

function OverviewOfEndoscopyEquipment() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        ENDOSCOPY_EQUIPMENT_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q13"
              )) && (
              <Col>
                <EndoscopicProcedureAmountBarChartQ13
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q14"
              )) && (
              <Col>
                <EquipmentSetupBarChartQ14
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q16"
              )) && (
              <Col>
                <SotfwareCostPerYearBarChartQ16
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            {(filteredQuestionListContext.state.allQuestions ||
              filteredQuestionListContext.state.filteredQuestions.includes(
                "Q17"
              )) && (
              <Col>
                <EndoscopyEquipmentAndServiceCostsBarChartQ17
                  countryFilterValue={filterContext.state.countryFilterValue}
                  roleFilterValue={filterContext.state.roleFilterValue}
                  facilityTypeFilterValue={
                    filterContext.state.facilityTypeFilterValue
                  }
                  facilitySizeFilterValue={
                    filterContext.state.facilitySizeFilterValue
                  }
                  facilityLocationFilterValue={
                    filterContext.state.facilityLocationFilterValue
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default OverviewOfEndoscopyEquipment;
