import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card, Form } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner.js";
import { useAPI } from "../../ApiContext.js";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function ClickThroughPieChart(props) {
  const { dashboardData } = useAPI();
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getQualifiedData(data) {
    let obj = data;

    let qualified = obj.rows.filter(
      (item, index) => item.formula === "clickthrough"
    )[0];

    return qualified.cells[0].formatted;

    // return (
    //   (finalArray.filter((obj) => obj.Q1 === idVal).length /
    //     finalArray.length) *
    //   100
    // ).toFixed(0);
  }

  const PieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    maintainAspectRatio: false,
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        title: { display: true, padding: 10 },
        onClick: null, // Disable legend item click event
        labels: {
          font: {
            //size: 12,
            //weight: "bold",
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          if (context.dataset.data[context.dataIndex] > 0) {
            return value;
          } else {
            return "";
          }
        },
        display: true,
        anchor: "center",
        align: "end",
        color: "white",
        font: {
          size: 13,
          weight: "bold",
        },
      },
    },
    elements: {
      arc: {
        spacing: 2,
      },
    },
  };
  function getChartData() {
    return {
      labels: ["Click Through"],
      datasets: [
        {
          data: [getQualifiedData(dashboardData.summary)],
          borderColor: colorPaletteState,
          backgroundColor: colorPaletteState,
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>
        <Form.Text>
          Participants who opened the first page of the survey
        </Form.Text>
      </Card.Header>
      <Card.Body>
        {dashboardData.summary && dashboardData.summary.rows.length > 0 ? (
          <div style={{ width: "100%", height: "60vh" }}>
            <Pie
              options={PieChartOptions}
              data={getChartData()}
              className={{ margin: "10px" }}
            />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
}

export default ClickThroughPieChart;
