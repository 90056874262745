import React from "react";
import { useContext } from "react";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

import { Container, FormGroup, FormLabel } from "react-bootstrap";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { FilterContext, FilteredQuestionListContext } from "../App";
import QuestionsDropDown from "./AutoMaintenance/QuestionsDropDown";
import BackToTopButton from "./BackToTopButton";
import {
  AUTO_MAINTENANCE_QUESTIONS,
  ENDOSCOPY_EQUIPMENT_QUESTIONS,
  KEY_CHALLENGES_QUESTIONS,
  OPSOPTI_QUESTIONS,
  OPS_REPORT_QUESTIONS,
  PRICING_PREFERENCE_QUESTION,
  SCRENNER_QUESTIONS,
  STAFF_TRAINING_QUESTIONS,
  SUPPORTBOT_QUESTIONS,
} from "../constants/constants";

import Collapsible from "react-collapsible";
import Screener from "./Screener/Screener";
import OverviewOfEndoscopyEquipment from "./OverviewOfEndoscopyEquipment/OverviewOfEndoscopyEquipment";
import KeyChallenges from "./ValidationOfKeyCustomerChallenges/KeyChallenges";
import OpsOpti from "./OpsOpti/OpsOpti";
import AutoMaintenance from "./AutoMaintenance/AutoMaintenance";
import StaffTraining from "./StaffTraining/StaffTraining";
import SupportBot from "./SupportBot/SupportBot";
import OpsReport from "./OpsReport/OpsReport";
import PricingPreference from "./PricingPreference/PricingPreference";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function Home() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <Container>
      <FormGroup>
        <FormLabel className="mt-3">Select Question(s)</FormLabel>
        <QuestionsDropDown />
      </FormGroup>

      {(filteredQuestionListContext.state.allQuestions ||
        SCRENNER_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>SCREENER</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <Screener />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        ENDOSCOPY_EQUIPMENT_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>OVERVIEW OF ENDOSCOPY EQUIPMENT</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <OverviewOfEndoscopyEquipment />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        KEY_CHALLENGES_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>KEY CHALLENGES</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <KeyChallenges />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        OPSOPTI_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>OPS OPTI</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <OpsOpti />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        AUTO_MAINTENANCE_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>AUTO MAINTENANCE</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <AutoMaintenance />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        STAFF_TRAINING_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>STAFF TRAINING</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <StaffTraining />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        SUPPORTBOT_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>SUPPORT BOT</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <SupportBot />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        OPS_REPORT_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>OPS SUPPORT</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <OpsReport />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        PRICING_PREFERENCE_QUESTION.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>PRICING PREFERENCES</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <PricingPreference />
          </Collapsible>
        </>
      )}

      <BackToTopButton />
    </Container>
  );
}

export default Home;
